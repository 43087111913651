import React, { useState, useEffect, useContext } from 'react'

import { Context } from '../Context'
import { WalletContext, shortenAddress } from './../web3-wallets'

const Debug = () => {
  const wallet = useContext(WalletContext)
  const {
    offer,
    currentNetworkIndex,
    networks,
    setStatus,
    setStatusErrorDescription,
    setMintTxHash,
    setConfirmModalboxOpen,
    setConfirmModalboxSuccess,
    setRedirectUrl
  } = useContext(Context)

  const [theme, setTheme] = useState('light') // light | dark

  const switchTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    document.documentElement.setAttribute('data-theme', newTheme)
    setTheme(newTheme)
  }

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight)
  }, [])

  return (
    <div className="Debug" id="debug">
      <div className="inner">
        <strong>You are the pixelhunter!</strong>
        <button onClick={switchTheme}>Switch Theme</button>

        <div>Status</div>
        <button onClick={() => {
          setStatus(null)
          setStatusErrorDescription(null)
          setMintTxHash(null)
          setRedirectUrl(null)
        }}>null</button>
        <button onClick={() => {
          setStatus('wait')
          setStatusErrorDescription(null)
          setMintTxHash(null)
          setRedirectUrl(null)
        }}>wait</button>
        <button onClick={() => {
          setStatus('error')
          setStatusErrorDescription('Generated error description')
          setMintTxHash(null)
          setRedirectUrl(null)
        }}>error</button>
        <br />
        <button onClick={() => {
          setStatus('success')
          setStatusErrorDescription(null)
          setMintTxHash('debug-test')
          setRedirectUrl(null)
        }}>success</button>
        <button onClick={() => {
          setStatus('success')
          setStatusErrorDescription(null)
          setMintTxHash('debug-test')
          setRedirectUrl('https://google.com')
        }}>success+url</button>

        <div>Modalboxes</div>
        <div>
          <button onClick={() => {
            setConfirmModalboxOpen(true)
            setConfirmModalboxSuccess(false)
          }}>"Confirm tx"</button>
          <button onClick={() => {
            setConfirmModalboxOpen(true)
            setConfirmModalboxSuccess(true)
          }}>"Tx submitted"</button>
        </div>

        <div>offer: {String(offer)}</div>
        <div>wallet.isConnected: {String(wallet.isConnected)}</div>
        <div>wallet.name: {String(wallet.name)}</div>
        <div>wallet.chainId: {String(wallet.chainId)}</div>
        <div>wallet.address: {String(shortenAddress(wallet.address))}</div>
        <button onClick={() => {
          if (currentNetworkIndex === null) {
            return
          }
          const network = networks[currentNetworkIndex]
          wallet.connect({ name: 'MetaMask', chainId: network.chain_id })
        }}>connect MM</button>
        <button onClick={() => {
          if (currentNetworkIndex === null) {
            return
          }
          const network = networks[currentNetworkIndex]
          wallet.connect({ name: 'WalletConnect', chainId: network.chain_id })
        }}>connect WC</button>
      </div>
    </div>
  )
}

export default Debug
