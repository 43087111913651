import React, { useState, useEffect, createContext, useContext } from 'react'
import _ from 'lodash-es'

import { WalletContext } from './web3-wallets'

// utils
import { apiUrl, explorerApiUrl } from './api'
import { CURRENCY_BASE } from './format-crypto/format'


interface ContextInterface {
  loadOffer: Function // todo: types
  offer: any //null | { [key: string]: any };
  isOfferNotFound: boolean
  supportedNetworks: any
  networks: any
  getNetworkFees: Function
  networkFees: any
  currentNetworkIndex: number | null
  setCurrentNetworkIndex: Function
  loadSupportedTokens: Function
  supportedTokens: any[]
  currentToken: any
  setCurrentToken: Function
  balances: any
  getBalance: Function
  currentTokenBalance: any
  accountBalance: any
  getGasCost: Function
  gasCostBase: any
  gasCostUSD: any
  quantity: number
  setQuantity: Function
  submode: null | 'direct' | 'crosschain'
  isWrongNetwork: boolean
  status: null | 'wait' |'success' | 'error'
  setStatus: Function
  statusErrorDescription: null | string
  setStatusErrorDescription: (desc: null | string) => void
  isConfirmModalboxOpen: boolean
  setConfirmModalboxOpen: Function
  isConfirmModalboxSuccess: boolean
  setConfirmModalboxSuccess: Function
  payTxHash: null | string
  setPayTxHash: Function
  mintTxHash: null | string
  setMintTxHash: Function
  redirectUrl: null | string
  setRedirectUrl: Function
}

export const Context = createContext<ContextInterface>({
  loadOffer: () => {},
  offer: null,
  isOfferNotFound: false,
  supportedNetworks: null,
  networks: [],
  getNetworkFees: () => {},
  networkFees: {},
  currentNetworkIndex: null,
  setCurrentNetworkIndex: () => {},
  loadSupportedTokens: () => {},
  supportedTokens: [],
  currentToken: undefined,
  setCurrentToken: () => {},
  balances: null,
  getBalance: () => {},
  currentTokenBalance: undefined,
  accountBalance: 0,
  getGasCost: () => {},
  gasCostBase: undefined,
  gasCostUSD: undefined,
  quantity: 1,
  setQuantity: () => {},
  submode: null,
  isWrongNetwork: false,
  status: null,
  setStatus: (status: null | 'wait' | 'success' | 'error') => {},
  statusErrorDescription: null,
  setStatusErrorDescription: (desc: null | string) => {},
  isConfirmModalboxOpen: false,
  setConfirmModalboxOpen: () => {},
  isConfirmModalboxSuccess: false,
  setConfirmModalboxSuccess: () => {},
  payTxHash: null,
  setPayTxHash: () => {},
  mintTxHash: null,
  setMintTxHash: () => {},
  redirectUrl: null,
  setRedirectUrl: () => {}
})

const ContextProvider = (props) => {
  const wallet = useContext(WalletContext)

  const [networks, setNetworks] = useState<any[]>([])
  const [networkFees, setNetworkFees] = useState<any>({})
  const [isWrongNetwork, setWrongNetwork] = useState<boolean>(false)
  const [offer, setOffer] = useState<any>(null)
  const [isOfferNotFound, setOfferNotFound] = useState<boolean>(false)
  const [currentNetworkIndex, setCurrentNetworkIndex] = useState<number | null>(null)
  const [supportedNetworks, setSupportedNetworks] = useState<any[] | null>(null)
  const [supportedTokens, setSupportedTokens] = useState<any[]>([])
  const [currentToken, setCurrentToken] = useState<any>(undefined)
  const [currentTokenBalance, setCurrentTokenBalance] = useState<any>(undefined)
  const [balances, setBalances] = useState<any>(null)
  const [accountBalance, setAccountBalance] = useState<number>(0)
  const [gasCostBase, setGasCostBase] = useState<any>(undefined)
  const [gasCostUSD, setGasCostUSD] = useState<any>(undefined)
  const [quantity, setQuantity] = useState<number>(1)
  const [status, setStatus] = useState<null | 'wait' | 'success' | 'error'>(null)
  const [statusErrorDescription, setStatusErrorDescription] = useState<null | string>(null)
  const [isConfirmModalboxOpen, setConfirmModalboxOpen] = useState(false)
  const [isConfirmModalboxSuccess, setConfirmModalboxSuccess] = useState(false)
  const [payTxHash, setPayTxHash] = useState<null | string>(null)
  const [mintTxHash, setMintTxHash] = useState<null | string>(null)
  const [redirectUrl, setRedirectUrl] = useState<null | string>(null)

  useEffect(() => {
    document.documentElement.setAttribute('data-status', status || '')
  }, [status])

  useEffect(() => {
    loadNetworks()
  }, [])

  useEffect(() => {
    if (offer && networks.length) {
      const isWrong = checkIsWrongNetwork()

      if (wallet.chainId) {
        if (!isWrong) {
          // network change: wallet => app
          const index = networks.findIndex(n => n.chainID === wallet.chainId)
          if (~index) {
            setCurrentNetworkIndex(index)
          } else {
            wallet.disconnect()
          }
        } else {
          wallet.disconnect()
        }
      }
    }
  }, [offer, wallet.chainId])

  useEffect(() => {
    if (supportedNetworks && wallet.isConnected) {
      loadBalances()
    }
  }, [supportedNetworks, wallet.isConnected, wallet.address, wallet.chainId])

  useEffect(() => {
    if (!wallet.isConnected) {
      setAccountBalance(0)
      setBalances(null)
      //setCurrentToken(undefined)
      //setCurrentNetworkIndex(0)
      //setQuantity(1)
    }
  }, [wallet.isConnected])

  useEffect(() => {
    if (balances) {
      const baseBalance = getBalance()
      setAccountBalance(baseBalance)
    }
  }, [balances])

  useEffect(() => {
    const _ = async () => {
      if (currentToken && balances) {
        const newCurrentTokenBalance = getBalance({ tokenAddress: currentToken.address })
        console.log('setCurrentTokenBalance', newCurrentTokenBalance, currentToken.address)
        setCurrentTokenBalance(newCurrentTokenBalance)
      } else {
        setCurrentTokenBalance(undefined)
      }
    }
    _()
  }, [currentToken, balances])

  /*
  const getNetworkByChainId = () => {}
  */

  const checkIsWrongNetwork = () => {
    console.log('checkIsWrongNetwork...')

    if (!supportedNetworks || !wallet.chainId) {
      console.log(`checkIsWrongNetwork stopped: supportedNetworks:`, supportedNetworks, 'wallet.chainId:', wallet.chainId)
      setWrongNetwork(false)
      return false
    }
    const supportedNetworkIds = supportedNetworks.map(network => network.chain_id)
    console.log('supportedNetworkIds', supportedNetworkIds)
    if (supportedNetworkIds.includes(wallet.chainId)) {
      setWrongNetwork(false)
      return false
    } else {
      setWrongNetwork(true)
      return true
    }
  }

  const loadOffer = async (id) => {
    console.log('loadOffer()', id)
    try {
      const url = `${apiUrl}/nft/${id}`
      const f = await fetch(url)
      if (f.status === 404) {
        console.warn('Offer 404')
        setOfferNotFound(true)
        return
      }
      const json = await f.json()
      if (json.error) {
        throw new Error(json.error)
      }
      const newOffer = json.message
      console.log('setOffer', newOffer)

      document.title = `Buy ${newOffer?.name || ''} NFT with any token`
      setOffer(newOffer)

      const newSupportedNetworks = newOffer.networks?.length
        ? newOffer.networks
        : newOffer.merchant?.networks

      console.log('setSupportedNetworks', newSupportedNetworks)
      setSupportedNetworks(newSupportedNetworks)

      if (newOffer.total_amount > 0 && newOffer.minted_amount >= 0 && newOffer.minted_amount >= newOffer.total_amount) {
        console.log('setQuantity (disable mint)', 0)
        setQuantity(0)
      } else {
        const getInitialQuantity = () => {
          const limitPerTx = newOffer.max_amount_per_transaction // number
          const limitLeft = newOffer.total_amount === 0
            ? undefined
            : newOffer.total_amount - newOffer.minted_amount // number, number

          const limit = limitLeft === undefined ? limitPerTx : Math.min(limitPerTx, limitLeft)

          const urlParams = new URLSearchParams(window.location.search)
          const qty = urlParams.get('qty')
          const qtyInt = parseInt(String(qty))
          if (qtyInt >= 1 && qtyInt <= limit) {
            return qtyInt
          }
          return 1
        }
        const newQuantity = getInitialQuantity()
        console.log('setQuantity', newQuantity)
        setQuantity(newQuantity)
      }
    } catch (error) {
      console.error('loadOffer error', error)
      setTimeout(() => {
        window.location.reload()
      }, 10000)
    }
  }

  const loadNetworks = async () => {
    const f = await fetch(`${apiUrl}/blockchain/`)
    const json = await f.json()
    const networks = json.message.map((item) => ({ // todo: solana support
      ...item,
      chainID: item.chain_id,
      icon: item.logo_url,
      fullName: item.name,
      name: item.short_name,
      data: {
        params: [{
          chainId: '0x' + item.chain_id.toString(16),
          chainName: item.name,
          nativeCurrency: {
            name: item.currency_name,
            symbol: item.currency_symbol,
            decimals: item.currency_decimals
          },
          rpcUrls: [item.rpc_url],
          blockExplorerUrls: [item.explorer_url]
        }]
      }
    }))
    networks.sort(net => {
      return (net.chainID === 56 || net.chainID === 97) ? -1 : 1
    })
    console.log('setNetworks:', networks)
    setNetworks(networks)
  }

  const loadSupportedTokens = async () => {
    console.log(`loadSupportedTokens()...`)

    if (!supportedNetworks) {
      throw new Error('Missing supportedNetworks')
    }

    if (!offer) {
      throw new Error('Missing offer')
    }

    const tokens = [
      ...offer.networks.map((network: any) => ({ // native tokens - from networks
        data: {
          symbol: network.currency_symbol,
          name: network.currency_symbol,
          fullName: network.currency_name,
          logo_url: network.currency_logo_url || network.logo_url,
          is_base: true,
          isStable: false,
        },
        chainId: network.chain_id,
        chain_id: network.chain_id,
        address: network.chain_id === -1 ? 'So11111111111111111111111111111111111111111' : '0x0000000000000000000000000000000000000000'
      })),
      ...offer.tokens.map((t: any) => ({ // other tokens
        data: {
          symbol: t.name,
          name: t.name,
          fullName: t.full_name,
          logo_url: t.logo_url,
          is_base: t.is_base,
          isStable: t.is_stable,
        },
        chainId: t.chain_id,
        chain_id: t.chain_id,
        address: t.contract_address
      }))
    ]

    //console.log('setSupportedTokens - unfiltered', tokens)

    const tokensFiltered = tokens.filter(token => {
      // todo: nft + tokens support
      return token.data.is_base // todo: remove is_base
    })

    tokensFiltered.sort((tokenA, tokenB) => tokenA.chain_id < tokenB.chain_id ? -1 : 1)

    tokensFiltered.sort(token => {
      return (token.chain_id === 56 || token.chain_id === 97) ? -1 : 1
    })

    console.log('setSupportedTokens', tokensFiltered)
    setSupportedTokens(tokensFiltered)
  }

  const getNetworkFees = async (mode) => {
    console.log('getNetworkFees()', mode)
    //console.log('networks', networks)

    if (!networks || !supportedNetworks) {
      return
    }

    const requests = supportedNetworks.map((supportedNetwork) => {
      const networkIndex = networks.findIndex(net => net.chain_id === supportedNetwork.chain_id)
      return getGasCostValues({
        mode,
        submode,
        networkIndex: +networkIndex,
        token: CURRENCY_BASE
      })
    })

    Promise.all(requests)
      .then((responses) => {
        const fees = {}

        responses.forEach((response) => {
          if (response) {
            fees[response.chainId] = {
              gasCostBase: response.gasCostBase,
              gasCostUSD: response.gasCostUSD
            }
          }
        })

        console.log('setNetworkFees', fees)
        setNetworkFees(fees)
      })
  }

  const loadBalances = async (/*{ address }*/) => {
    console.log('loadBalances...')

    if (!wallet.isConnected) {
      setAccountBalance(0)
      return
    }

    //@ts-ignore
    const balanceUrls = supportedNetworks.reduce((acc, net) => {
      /*{ // todo: optimize queries
        chainId: net.chain_id,
        url: `${explorerApiUrl}/ethlike/native_balances/${wallet.address}?chains=${net.chain_id}`
      },*/
      if (wallet.chainId === -1 && net.chain_id === -1) {
        acc.push({
          chainId: net.chain_id,
          url: `${explorerApiUrl}/solana/native_balance_and_tokens/${wallet.address}`
        })
      }
      if (wallet.chainId && wallet.chainId > 0 && net.chain_id > 0) {
        acc.push({
          chainId: net.chain_id, // also contains native tokens
          // tokens_balances -> native_balances : todo: tokens support
          url: `${explorerApiUrl}/ethlike/native_balances/${wallet.address}?chains=${net.chain_id}`
        })
      }
      return acc
    }, [])

    //console.log('balanceUrls', balanceUrls)

    const balances_ = (await Promise.all(balanceUrls.map(async ({ url, chainId }) => {
      console.log('fetch url', url)
      const res = await fetch(url)
      const json = await res.json()
      const balanceItems = chainId === -1
        ? [
          {
            address: 'So11111111111111111111111111111111111111111',
            name: 'Solana',
            symbol: 'SOL',
            logo: '#',
            decimals: 9,
            balance: json.nativeBalance.lamports
          }
        ]
        : [json.balances[chainId] || {
          address: '0x0000000000000000000000000000000000000000',
            name: '',
            symbol: '',
            logo: '',
            decimals: 18,
            balance: 0
        }] // todo something: [] only for tokens_balances
      console.log('balanceItems', balanceItems)
      return {
        chainId,
        balanceItems
      }
    //@ts-ignore
    }))).reduce((acc, { chainId, balanceItems }) => {
      return _.merge(acc, { [chainId]: balanceItems } )
    }, {})

    console.log('setBalances', balances_)
    setBalances(balances_)
  }

  const getBalance = ({
    //@ts-ignore
    chainId = networks[currentNetworkIndex] && networks[currentNetworkIndex].chain_id,
    address = null,
    tokenAddress = null
  } = {}) => {
    console.log('getBalance()', chainId, address, tokenAddress)
    /* from backend */
    if (!balances || !chainId || !balances[chainId]) {
      return 0
    }

    let tokenAddressApplied: null | string = tokenAddress
    if (tokenAddressApplied === null) {
      tokenAddressApplied = chainId === -1
        ? 'So11111111111111111111111111111111111111111'
        : '0x0000000000000000000000000000000000000000'
    }

    const findBalance = balances[chainId].find(tokenBalance => tokenBalance.address === tokenAddressApplied)
    if (!findBalance) {
      return 0
    }

    const baseBalanceWei = findBalance.balance
    const decimals = findBalance.decimals
    const baseBalance = baseBalanceWei / Math.pow(10, decimals)
    return baseBalance
  }

  const getGasCostValues = async ({ mode, submode, networkIndex = currentNetworkIndex, token = currentToken || false }) => {
    /*
    depends on:
      - mode
      - network
      - payment coin
      - nft quantity
    */
    console.log('getGasCostValues()', mode, submode, networkIndex, token)

    if (!networks || !networks.length || networkIndex === null) {
      return
    }

    const { chainID } = networks[networkIndex]
    if (!chainID) {
      return
    }

    const isPayInToken = token && token !== CURRENCY_BASE && !(token?.address === '0x0000000000000000000000000000000000000000' || token?.address === 'So11111111111111111111111111111111111111111')
    console.log('isPayInToken:', isPayInToken)

    let gasAmount

    // Approximate tx price
    // todo later/maybe: real gas

    if (submode === 'direct' || !submode) {
      /*
        todo: measure other contracts

        ## nft, shrooms, direct mint, medium speed
        mint 1 shroom - 162797 gwei
        mint 2 shroom - 278335 gwei
        mint 3 shroom - 393873 gwei
        mint 4 shroom - 509411 gwei
      */
      gasAmount = 162797 + (278335 - 162797) * (quantity - 1)
    } else if (submode === 'crosschain') {
      gasAmount = 32121 // SubscriptionManager.oneTimeEthPayment
      // todo: autoswap protocol gas
    } else {
      throw new Error(`Unknown submode ${submode}`)
    }

    if (chainID === -1) {
      const result = { chainId: chainID, gasCostBase: 0.0000000001, gasCostUSD: 0.0000000001 } // todo: solana tx fees
      console.log(`<- getGasCostValues`, mode, submode, networkIndex, token, '<=', result)
      return result
    }

    // todo: optimize (comma-separated chainIds)
    const url = `${explorerApiUrl}/gas_price?chains=${chainID}`
    console.log(`fetch gas price from ${url}...`)
    const res = await fetch(url)
    if (res.status !== 200) {
      setStatus('error')
      setStatusErrorDescription(`Cannot get gas price for ${chainID} [back_${res.status}]`)
      return
    }
    const json = await res.json()
    console.log(` <- ${json}`)

    if (!json[chainID] /*|| !json[chainID].gwei || json[chainID].usd*/) {
      throw new Error(`Cannot get gas price for chainId ${chainID} from ${url}`)
    }

    const gasPriceGwei = json[chainID].gwei
    const GWEI_TO_BASE = 0.000000001
    const gasCostBase = gasPriceGwei * GWEI_TO_BASE * gasAmount
    const gasCostUSD = json[chainID].usd * gasAmount

    const result = { chainId: chainID, gasCostBase, gasCostUSD }
    console.log(`<- getGasCostValues`, mode, submode, networkIndex, token, '<=', result)
    return result
  }

  const getGasCost = async (mode, submode) => {
    console.log('getGasCost()', mode, submode)

    const gasCostValues = await getGasCostValues({ mode, submode })
    if (!gasCostValues) {
      return
    }
    const { gasCostBase, gasCostUSD } = gasCostValues
    if (gasCostBase) {
      console.log('setGasCostBase', gasCostBase)
      setGasCostBase(gasCostBase)
    }
    if (gasCostUSD) {
      console.log('setGasCostUSD', gasCostUSD)
      setGasCostUSD(gasCostUSD)
    }
  }

  //const getSubmode = () => {
    const submode = (!offer || !networks || currentNetworkIndex === null)
      ? null
      : offer.chain_id === networks[currentNetworkIndex].chain_id
        ? 'direct'
        : 'crosschain'
  //  return submode
  //}


  return (
    <Context.Provider value={{
      loadOffer,
      offer,
      isOfferNotFound,
      supportedNetworks,
      networks,
      getNetworkFees,
      networkFees,
      currentNetworkIndex,
      setCurrentNetworkIndex,
      loadSupportedTokens, supportedTokens,
      currentToken, setCurrentToken,
      balances, getBalance,
      currentTokenBalance,
      accountBalance,
      getGasCost,
      gasCostBase,
      gasCostUSD,
      quantity,
      setQuantity,
      submode,
      isWrongNetwork,
      status, setStatus,
      statusErrorDescription, setStatusErrorDescription,
      isConfirmModalboxOpen, setConfirmModalboxOpen,
      isConfirmModalboxSuccess, setConfirmModalboxSuccess,
      payTxHash, setPayTxHash,
      mintTxHash, setMintTxHash,
      redirectUrl, setRedirectUrl
    }}>
      {props.children}
    </Context.Provider>
  )
}

export default ContextProvider
