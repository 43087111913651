/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import base58 from 'bs58'
import { Keypair, Message, Transaction } from '@solana/web3.js'

import { formatValue, CURRENCY_BASE, CURRENCY_USD, CURRENCY_CRYPTO } from './format-crypto/format'
import { WalletContext, isValidAddress, toHex, BigNumber } from './web3-wallets'
import { Context } from './Context'
import Page404 from './404'

// icons
import CoinUnknownIcon from './images/coin-unknown.svg'
import AddIcon from './images/add.svg'
import SubmitLoaderIcon from './images/submit-loader.svg'
import SubmitSuccessIcon from './images/submit-success.svg'

import UnitEthIcon from './images/unit-eth.png'
import UnitMaticIcon from './images/unit-matic.png'
import UnitBSCIcon from './images/unit-bsc.png'
import UnitTONIcon from './images/unit-ton.svg'

import NetworkBscIcon from './images/network-bsc.svg'
import NetworkEthereumIcon from './images/network-ethereum.svg'
import NetworkOecIcon from './images/network-oec.svg'
import NetworkPolygonIcon from './images/network-polygon.svg'
import NetworkArbitrumIcon from './images/network-arbitrum.svg'
import NetworkAvalancheIcon from './images/network-avalanche.svg'
import NetworkFantomIcon from './images/network-fantom.svg'
import NetworkOptimismIcon from './images/network-optimism.svg'
import NetworkSolanaIcon from './images/network-solana.svg'
import NetworkTonIcon from './images/network-ton.svg'
import NetworkUnknownIcon from './images/network-unknown.svg'

import { IconSelect, IconAdd, IconBack, IconTw } from './components/Icons'

// components
import Header from './components/Header'
import Footer from './components/Footer'
import Skeleton from './components/Skeleton'
import SelectWallet from './components/SelectWallet'
import SelectToken from './components/SelectToken'
import ConfirmTx from './components/ConfirmTx'
import Indicator from './components/Indicator'
import TimerButton from './components/TimerButton'
import Debug from './components/Debug'

// utils
import { apiUrl, explorerApiUrl } from './api'


const App = (props) => {
  const mode = 'nft'

  const { id } = useParams()

  const wallet = useContext(WalletContext)
  const {
    loadOffer, offer, isOfferNotFound,
    supportedNetworks,
    networks,
    loadSupportedTokens, supportedTokens,
    currentToken, setCurrentToken,
    currentNetworkIndex, setCurrentNetworkIndex,
    balances, getBalance,
    //currentTokenBalance,
    getNetworkFees,
    getGasCost,
    gasCostBase,
    gasCostUSD,
    quantity, setQuantity,
    submode,
    isWrongNetwork,
    status, setStatus,
    statusErrorDescription, setStatusErrorDescription,
    isConfirmModalboxOpen, setConfirmModalboxOpen,
    isConfirmModalboxSuccess, setConfirmModalboxSuccess,
    setPayTxHash,
    mintTxHash, setMintTxHash,
    redirectUrl, setRedirectUrl
  } = useContext(Context)

  const [isSelectWalletOpen, setSelectWalletOpen] = useState(false)
  const [isSelectTokenOpen, setSelectTokenOpen] = useState(false)

  const [isDebugOpen, setDebugOpen] = useState(false)
  const [price, setPrice] = useState<number | undefined>(undefined)
  const [nftUsdPrice, setNftUsdPrice] = useState<number | undefined>(undefined)
  const [/*crosschainFeeBase*/, setCrosschainFeeBase] = useState<number | null>(null)
  const [/*crosschainFeeUSD*/, setCrosschainFeeUSD] = useState<number | null>(null)
  const [isEnoughCurrentToken, setEnoughCurrentToken] = useState<boolean | undefined>(undefined)
  const [isEnoughBaseCurrency, setEnoughBaseCurrency] = useState<boolean | undefined>(undefined)
  const [isHideTxCost, setHideTxCost] = useState(true)
  const [quantityInputValue, setQuantityInputValue] = useState(1)

  const [toAddress, setToAddress] = useState('')
  const [isCrosstype, setCrosstype] = useState(false)
  const [crosstypeRecipient, setCrosstypeRecipient] = useState('')
  const [isCrosstypeRecipientFocused, setCrosstypeRecipientFocused] = useState(false)
  const [isCrosstypeRecipientValid, setCrosstypeRecipientValid] = useState(true)

  const [waitingSubmit, setWaitingSubmit] = useState(false)
  const [successSubmit, setSuccessSubmit] = useState(false)

  useEffect(() => {
    loadOffer(id)
  }, [])

  useEffect(() => {
    if (supportedNetworks) {
      loadSupportedTokens()
    }
  }, [supportedNetworks])

  useEffect(() => {
    getPriceInToken(currentToken)
  }, [currentToken, quantity])


  let isWalletRestored = false

  useEffect(() => { // restore network / autoselect network (if not connected)
    (async () => {
      if (!networks.length || !supportedNetworks) {
        return
      }

      if (!isWalletRestored && currentNetworkIndex === null) {
        isWalletRestored = true
        const success = await wallet.restore()
        if (success) {
          console.log('restore success', success)
          return
        } else {
          console.log('restore success', success)
          //if (currentNetworkIndex === null /*&& !wallet.address*/)
          //if (!wallet.isConnected || !wallet.address || !wallet.chainId) {
          //  return
          //}

          const offerNetworkIds = supportedNetworks.map(net => net.chain_id)

          const tmpIndex = networks.findIndex(net =>
            offerNetworkIds.includes(net.chain_id)
          )

          /*const indexOfWalletNetwork = networks.findIndex(net =>
            net.chainID === wallet.chainId
          )*/
          const indexOfWalletNetwork = -1

          console.log('tmpIndex', tmpIndex, 'indexOfWalletNetwork', indexOfWalletNetwork)

          if (indexOfWalletNetwork !== -1) {
            /*await */setCurrentNetworkIndex(indexOfWalletNetwork)
          } else if (tmpIndex !== -1) {
            /*await */setCurrentNetworkIndex(tmpIndex)
          }
        }
      }
    })()
  }, [supportedNetworks, networks, setCurrentNetworkIndex/*, wallet.address*/])

  useEffect(() => { // token autoselect | todo: value after disconnect
    if (!supportedTokens || !networks || currentNetworkIndex === null) {
      return
    }

    const currentNetwork = networks[currentNetworkIndex]
    if (!currentToken || currentToken.chain_id !== currentNetwork.chain_id) {
      console.log('token autoselect...')
      let tokenToSet
      tokenToSet = supportedTokens.filter(t => // Base coin is a prority
        (t.address === '0x0000000000000000000000000000000000000000' || t.address === 'So11111111111111111111111111111111111111111')
        && t.chain_id === currentNetwork.chain_id /*&& balance > 0*/
      )[0]
      if (!tokenToSet) {
        tokenToSet = supportedTokens.filter(t => // Other tokens have less priority
          !(t.address === '0x0000000000000000000000000000000000000000' || t.address === 'So11111111111111111111111111111111111111111')
          && t.chain_id === currentNetwork.chain_id /*&& balance > 0*/
        )[0]
      }
      /*
      if (!tokenToSet && supportedTokens.length === 1) { // No select? Seleсt the one.
        tokenToSet = supportedTokens[0]
      }
      */

      console.log('setCurrentToken: ', tokenToSet)
      setCurrentToken(tokenToSet)
    }
  }, [supportedTokens, /*wallet.isConnected,*/ currentNetworkIndex])

  useEffect(() => {
    getGasCost(mode, submode)
  }, [networks, currentNetworkIndex, currentToken])

  useEffect(() => {
    getNetworkFees(mode)
  }, [networks, supportedNetworks, offer])

  useEffect(() => { // get nft price [USD]
    if (!offer) {
      setNftUsdPrice(undefined)
      return
    }
    (async () => {
      if (!offer.price_value || !offer.chain_id) {
        throw new Error(`Missing offer.price_value (${offer.price_value}) or offer.chain_id (${offer.chain_id})`)
      }

      // todo: tokens support (not only base)
      const nftPriceInUsd = offer.price_value * Number(await getBasePriceInUSD(offer.chain_id))
      setNftUsdPrice(nftPriceInUsd)
    })()
  }, [offer])

  useEffect(() => {
    getGasCost(mode, submode)
  }, [quantity])

  useEffect(() => {
    setCrosschainFeeBase(null)
    setCrosschainFeeUSD(null)

    setEnoughCurrentToken(undefined)
    setEnoughBaseCurrency(undefined)
    setHideTxCost(true)
  }, [currentNetworkIndex]) // todo: currentToken?

  useEffect(() => {
    async function setEnough() {
      /*
        costs:
          1) price [current token] - already includes 2) (!!!)
          2) (if nft+crosschain) crosschainFeeBase [current token]
          3) gasCostBase [base currency]
      */
      if (currentToken) {
        const isPayInBase = currentToken.address === '0x0000000000000000000000000000000000000000' || currentToken.address === 'So11111111111111111111111111111111111111111'

        if (isPayInBase) {
          const baseBalance = getBalance()
          let costs = 0
          if (price) {
            costs += price // includes 'crosschainFeeBase'
          }
          if (gasCostBase) {
            costs += gasCostBase
          }
          if (costs) {
            setEnoughCurrentToken(baseBalance >= costs)
            setEnoughBaseCurrency(baseBalance >= costs)

            if (!isEnoughBaseCurrency && price) {
              const isEnoughWithoutFee = baseBalance >= (costs - gasCostBase)
              setHideTxCost(!isEnoughWithoutFee)
            } else {
              setHideTxCost(true)
            }
          }
        } else { // pay in token
          const baseBalance = getBalance()
          const tokenBalance = getBalance({ tokenAddress: currentToken.address })

          let costsToken = 0
          let costsBase = 0
          if (price) {
            costsToken += price // includes 'crosschainFeeBase'
          }
          if (gasCostBase) {
            costsBase += gasCostBase
          }
          setEnoughCurrentToken(tokenBalance >= costsToken)

          //console.log('baseBalance', baseBalance)
          //console.log('gasCostBase', gasCostBase)

          setEnoughBaseCurrency(baseBalance >= costsBase)
          setHideTxCost(!isEnoughBaseCurrency)
        }
      }
    }
    setEnough()
  }, [currentToken, gasCostBase, wallet.address, price, balances])

  useEffect(() => {
    (async () => {
      if (wallet.address) {
        await createCustomer()
      }
    })()
  }, [wallet.address])

  useEffect(() => {
    if (!offer || !wallet.chainId) {
      setCrosstype(false)
      return
    }
    const newCrosstype = offer.chain_id > 0 !== wallet.chainId > 0
    setCrosstype(newCrosstype)
  }, [offer, wallet.chainId])


  useEffect(() => {
    (async () => {
      if (!offer || !offer.chain_id || !wallet.address) {
        setToAddress('')
        return
      }

      if (!isCrosstype) {
        setToAddress(wallet.address)
        return
      }

      if (crosstypeRecipient) {
        const isValidCrosstypeRecipient = await isValidAddress(offer.chain_id, crosstypeRecipient)
        setCrosstypeRecipientValid(isValidCrosstypeRecipient)

        if (isValidCrosstypeRecipient) {
          setToAddress(crosstypeRecipient)
        } else {
          setToAddress('')
        }
      } else {
        setCrosstypeRecipientValid(true)
        setToAddress('')
      }
    })()
  }, [wallet.address, offer, isCrosstype, crosstypeRecipient, setToAddress])

  useEffect(() => {
    setQuantityInputValue(quantity)
  }, [quantity])

  const getBasePriceInUSD = async (chainId) => {
    console.log('getBasePriceInUSD()', chainId)
    const url = `${explorerApiUrl}/token_price?chain=${chainId}&tokens_addresses=0x0000000000000000000000000000000000000000`
    console.log(`...from ${url}`)
    const res = await fetch(url)
    if (res.status !== 200) {
      setStatus('error')
      setStatusErrorDescription(`Cannot get token_price [back_${res.status}]`)
      return
    }
    const json = await res.json()
    const basePriceInUSD = json[chainId]['0x0000000000000000000000000000000000000000'].USD // todo: solana support
    console.log('<-', basePriceInUSD)
    return basePriceInUSD
  }

  // not base
  const getTokenPriceInUSD = async (chainId, tokenAddress) => {
    console.log('getTokenPriceInUSD()', chainId, tokenAddress)
    const url = `${explorerApiUrl}/token_price?chain=${chainId}&tokens_addresses=${tokenAddress}`
    console.log(`...from ${url}`)
    const res = await fetch(url)
    if (res.status !== 200) {
      setStatus('error')
      setStatusErrorDescription(`Cannot get token_price [back_${res.status}]`)
      return
    }
    const json = await res.json()
    const tokenPriceInUSD = json[chainId][tokenAddress].USD
    console.log('<-', tokenPriceInUSD)
    return tokenPriceInUSD
  }

  const getPriceInToken = async (token) => {
      setPrice(undefined)

      console.log(`getPriceInToken({🠗}) - ${token?.data?.symbol}`, token)

      if (!token || !supportedTokens || !offerPrice || !offer || !offer.active) {
        console.log('getPriceInToken - stopped')
        return
      }

      let offerPriceInUSD
      if (offerPrice.isFiat) {
        offerPriceInUSD = offerPrice.value * quantity
      } else {
        const offerPriceChain = networks.filter(network => network.currency_symbol === offerPrice.symbol)[0]
        const offerPriceChainID = offerPriceChain?.chainID
        console.log('offerPriceChainID:', offerPriceChainID)

        if (!offerPriceChainID) {
          console.error('Cannot calculate offerPriceChainID')
          price && setPrice(undefined)
          return
        }

        if (offerPrice.isBase) {
          if (offer.chain_id !== networks[Number(currentNetworkIndex)].chain_id) {
            // crosschain mint

            /*
            quantity-dependent price value,
            also depends on the cost of calling a contract on another network
            calculated on the back
            */

            if (!currentToken.chainId || !currentToken.address) {
              return
            }

            // todo: not only base
            const res = await fetch(`${apiUrl}/nft/${id}/mint-price/?nft_amount=${quantity || 1}`)
            if (res.status !== 200) {
              setStatus('error')
              setStatusErrorDescription(`Cannot get mint-price [back_${res.status}]`)
              return
            }
            const json = await res.json()
            if (json.error) {
              throw new Error(json.error)
            }

            // 1) addition, not sum
            // 2) in ETH, not in nft-base (temporarily)
            // todo: tokens support (not only base)
            let mintAdditionBase = 0

            if (json.message && json.message.service_fee && json.message.service_fee[currentToken.chainId] && json.message.service_fee[currentToken.chainId][currentToken.address]) {
              mintAdditionBase = json.message.service_fee[currentToken.chainId][currentToken.address]
            }
            if (!mintAdditionBase) {
              console.warn(`Broken mintAdditionBase ${mintAdditionBase}`)
            }

            //console.log('mintAdditionBase:', mintAdditionBase, '(temporarily in ETH)')

            const additionBasePriceInUSD = await getBasePriceInUSD(currentToken.chainId)
            const mintAdditionUSD = mintAdditionBase * additionBasePriceInUSD

            setCrosschainFeeBase(mintAdditionBase)
            setCrosschainFeeUSD(mintAdditionUSD)

            // const nftChainId = offer.chain_id
            // todo: nft price not only in nft-chain-base
            const basePriceInUSD = await getBasePriceInUSD(offerPriceChainID)

            //console.log('basePriceInUSD:', basePriceInUSD, offerPriceChainID)
            offerPriceInUSD = offerPrice.value * quantity * basePriceInUSD + mintAdditionUSD
            //console.log('offerPriceInUSD:', offerPriceInUSD)
          } else {
            const basePriceInUSD = await getBasePriceInUSD(offerPriceChainID)
            offerPriceInUSD = offerPrice.value * basePriceInUSD * quantity
          }
        }

        if (offerPrice.isToken) {
          const tokenAddress = '' // todo: tokens support
          offerPriceInUSD = offerPrice.value * await getTokenPriceInUSD(offerPriceChainID, tokenAddress) * quantity
        }
      }

      if (!offerPriceInUSD) {
        console.error('Cannot calculate offerPriceInUSD')
        price && setPrice(undefined)
        return
      }

      const { chainID } = networks[Number(currentNetworkIndex)]

      if (token.address === '0x0000000000000000000000000000000000000000' || token.address === 'So11111111111111111111111111111111111111111') {
        const basePriceInUSD = await getBasePriceInUSD(chainID)
        if (!basePriceInUSD) {
          console.error('Cannot get base currency price in USD')
          price && setPrice(undefined)
          return
        }
        const newPrice = offerPriceInUSD / basePriceInUSD
        console.log('setPrice', newPrice)
        setPrice(newPrice)
      } else { // not base currency
        const tokenPriceInUSD = await getTokenPriceInUSD(chainID, token.address)
        if (!tokenPriceInUSD) {
          console.error('Cannot fetch tokenPriceInUSD, unsetPrice')
          price && setPrice(undefined)
          return
        }
        const newPrice = offerPriceInUSD / tokenPriceInUSD
        console.log('setPrice', newPrice)
        setPrice(newPrice)
      }
    }
  // /getPriceInToken

  const createCustomer = async () => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const urlParams = new URLSearchParams(window.location.search)
    const ref = urlParams.get('ref')

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        wallet: wallet.name,
        address: wallet.address,
        referral: ref || undefined
      }),
    }

    try {
      const res = await fetch(`${apiUrl}/customer/`, requestOptions)
      const json = await res.json()
      console.log('Customer created', json)
    } catch (e) {
      console.error('Cannot create customer,', e)
    }
  }

  const submit = async () => {
    console.log('submit()')
    try {
      setWaitingSubmit(true)
      setStatusErrorDescription(null)
      setMintTxHash(null)
      setStatus('wait')
      setConfirmModalboxSuccess(false)
      setConfirmModalboxOpen(true)

      if (!wallet.isConnected) {
        throw new Error('No wallet')
      }

      if (!toAddress) {
        throw new Error('Missing toAddress')
      }

      console.log('currentToken:', currentToken)
      console.log('currentToken.address:', currentToken.address)
      console.log('toAddress:', toAddress)


      const headers = new Headers()
      headers.append('Content-Type', 'application/json')

      console.log('fetch build-tx...')
      const res = await fetch(`${apiUrl}/customer_nft/build-tx/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          customer_address: wallet.address,
          send_to: toAddress,
          token: {
            chain_id: wallet.chainId,
            contract_address: wallet.chainId === -1
              ? 'So11111111111111111111111111111111111111111'
              : '0x0000000000000000000000000000000000000000'
          },
          nft: id,
          nft_amount: quantity
        }),
      })
      if (res.status !== 200) {
        throw new Error(`Cannot get build-tx [back_${res.status}]`)
      }
      const json = await res.json()
      const buildedTx = json.message
      console.log('buildedTx:', buildedTx)

      const fields = [
        'order_id',
        //'chainId', // missing for direct mints
        'from',
        'to',
        'value',
        'data',
        //'gas',
        'gasPrice',
        // 'nonce', // can be 0
      ]
      fields.forEach((field) => {
        if (!buildedTx[field]) {
          throw new Error(`Wrong buildedTx prop '${field}' = ${buildedTx[field]}`)
        }
      })

      const orderId = buildedTx.order_id
      let txHash

      if (Number(wallet.chainId) > 0) {
        const appChainIdHex = networks[Number(currentNetworkIndex)].data.params[0].chainId
        //const buildedTxChainIdHex = toHex(buildedTx.chainId)

        /*if (appChainIdHex !== buildedTxChainIdHex) {
          throw new Error(`appChainIdHex ${appChainIdHex} !== buildedTxChainIdHex ${buildedTxChainIdHex}`)
        }*/

        if (!wallet.address) {
          throw new Error('No wallet.address')
        }

        const rawTransaction = {
          chainId: appChainIdHex,
          from: wallet.address, //buildedTx.from,
          to: buildedTx.to,
          value: toHex(BigNumber.from(buildedTx.value)),
          data: buildedTx.data,
          nonce: toHex(buildedTx.nonce),
          //gas: buildedTx.gas,
          gasPrice: buildedTx.gasPrice
        }

        /*if (!rawTransaction.nonce) {
          const nonce = await web3.eth.getTransactionCount(String(wallet.address))
          rawTransaction.nonce = web3.utils.toHex(nonce)
          console.log('(client-side) nonce:', nonce, rawTransaction.nonce)
        }

        if (!rawTransaction.gas) {
          const gas = await web3.eth.estimateGas(rawTransaction)
          rawTransaction.gas = web3.utils.numberToHex(gas)
          console.log('(client-side) estimated gas:', gas, rawTransaction.gas)
        }*/

        console.log('raw tx to send:', rawTransaction)
        console.log('Wallet: confirm request...')
        txHash = await wallet.sendTx(rawTransaction)
        console.log('Wallet: confirmed!')
        console.log('sended, txHash:', txHash)
      }

      if (wallet.chainId === -1) {
        /*
        const decodedData = JSON.parse(atob(buildTxData.data))
        // { "tx": base64StringMessage, "signs": [signature] }
        */

        const dataForSign = atob(buildedTx.tx)
        const rawMessage = new Uint8Array(dataForSign.length)
        for (let i = 0; i < dataForSign.length; i += 1) {
          rawMessage[i] = dataForSign.charCodeAt(i)
        }
        const msg = Message.from(rawMessage)

        const signers: Keypair[] = buildedTx.signs.map((raw_pk: string) => {
          return Keypair.fromSecretKey(base58.decode(raw_pk))
        })

        const tx = Transaction.populate(msg)
        tx.recentBlockhash = buildedTx.blockhash

        console.log('Wallet: confirm request...')
        txHash = await wallet.sendTx(tx, { signers })
        console.log('Wallet: confirmed!')
        console.log('sended, txHash:', txHash)
      }

      if (!txHash) {
        throw new Error('No txHash')
      }

      setPayTxHash(txHash)
      setConfirmModalboxSuccess(true)

      //try {
        /*
          pre-confirm
          (this will be useful if the user has not waited for the confirmation of the transaction)
        */
        await confirmMint({ orderId, txHash, isInitial: true })
      //} catch (e) {
      //  console.error('Cannot pre-confirm', e)
      //}

      if (!wallet.provider) {
        throw new Error('No wallet.provider')
      }

      if (Number(wallet.chainId) > 0) {
        console.log('Mining...')
        let isMined = false
        while (!isMined) {
          if (await wallet.provider.getTransactionReceipt(txHash)) {
            isMined = true
          }
          await new Promise(r => setTimeout(r, 500))
        }
        console.log('Mined!')
      }

      if (wallet.chainId === -1) {
        console.log('Mining... (solana mock)')
        await new Promise(r => setTimeout(r, 50 * 1000))
        console.log('Mined! (solana mock)')
      }

      //try {
        await confirmMint({ orderId, txHash })
      //} catch (e) {
      //  console.error(JSON.stringify(e))
      //  throw new Error('Cannot post-confirm')
      //}

      setStatus('success')
      setWaitingSubmit(false)
      setSuccessSubmit(true)
      //redirectTimer()
    } catch (error) {
      //@ts-ignore
      if (error.code === 4001) { // User denied transaction signature
        setStatus(null)
      } else {
        console.error(error)
        setStatus('error')
        //@ts-ignore
        setStatusErrorDescription(String(error.message))
      }
      setWaitingSubmit(false)
      setConfirmModalboxOpen(false)
      setConfirmModalboxSuccess(false)
    }
  }

  const confirmMint = async ({ orderId, txHash, isInitial = false }) => {
    console.log(`confirmMint()... (${isInitial ? 'Pre-confirm' : 'Post-confirm'})`)

    const urlParams = new URLSearchParams(window.location.search)
    const ref = urlParams.get('ref')

    const chainId = networks[Number(currentNetworkIndex)].chainID

    const body = {
      'status': isInitial ? 'init_submit' : 'sent_client_txn',
      'initial': isInitial,
      'chain_id': chainId,
      'token': chainId === -1 ? 'So11111111111111111111111111111111111111111' : '0x0000000000000000000000000000000000000000', // todo: tokens support
      'customer': wallet.address,
      'nft_collection': id,
      'nft_amount': quantity,
      'txn_hash': txHash,
      'send_to': toAddress,
      'referral': ref || undefined
    }

    for (let key in body) {
      const value = body[key]
      if (key !== 'referral' && typeof value === 'undefined') {
        throw new Error(`Missing prop ${key}`)
      }
    }

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(body),
    }
    console.log(`${isInitial ? 'Pre-confirm' : 'Post-confirm'} PUT body:`, body)

    const res = await fetch(`${apiUrl}/customer_nft/${orderId}`, requestOptions)
    if (res.status !== 200) {
      throw new Error(`Cannot ${isInitial ? 'pre-confirm' : 'post-confirm'} [back_${res.status}]`)
    }
    const json = await res.json()

    if (!json.success) {
      throw new Error(`Cannot ${isInitial ? 'pre-confirm' : 'post-confirm'} (json error)`)
    }
    if (isInitial) {
      console.log('Pre-confirm OK:', json)
    } else {
      console.log('Post-confirm OK:', json)

      console.log('answer.mintTxHash', json?.message?.txn_hash)
      const newMintTxHash = json?.message?.txn_hash || null

      console.log('answer.redirectUrl', json?.message?.redirect_url)
      let newRedirectUrl = json?.message?.redirect_url || offer?.success_page_url || null
      if (newRedirectUrl && newRedirectUrl.includes('%MINTANDSEND_TX_HASH%') && newMintTxHash) {
        newRedirectUrl = newRedirectUrl.replace('%MINTANDSEND_TX_HASH%', newMintTxHash)
      }

      setMintTxHash(newMintTxHash)
      setRedirectUrl(newRedirectUrl)
    }
  }

  const redirectTimer = () => {
    console.log('redirectTimer...')
    setTimeout(() => {
      console.log('redirectTimer end')
      if (redirectUrl) {
        window.location.href = redirectUrl
      } else {
        console.log('(no redirect)')
      }
    }, 2000)
  }


  const currencyType = offer?.price_currency_type
  const currencyValue = offer?.[`price_currency_${currencyType}`] // String or Object

  //console.log('(offer) currencyType', currencyType)
  //console.log('(offer) currencyValue', currencyValue)

  const offerPrice = currencyValue && {
    isFiat: currencyType === 'fiat',
    isBase: currencyType === 'native',
    isToken: currencyType === 'token',
    isStable: currencyValue.is_stable || false,
    value: offer.price_value,
    symbol: currencyValue.name || currencyValue,
    unitToShow: currencyValue.name || currencyValue,
    isUnitPrefixed: false
  }

  if (
    offerPrice?.symbol === 'USD'
  ) {
    offerPrice.unitToShow = '$'
    offerPrice.isUnitPrefixed = true
  }
  if (
    offerPrice?.symbol === 'ETH'
  ) {
    offerPrice.unitToShow = 'Ξ'
    //offerPrice.isUnitPrefixed = true
  }
  //console.log('offerPrice', offerPrice)


  const nftPrice = !offer?.price_value
    ? null
    : (() => {
      // todo: tokens support
      const priceETH = {
        symbol: 'ETH',
        icon: UnitEthIcon,
        valueFormatted: formatValue(CURRENCY_CRYPTO, offer?.price_value)
      }
      const priceBNB = {
        symbol: 'BNB',
        icon: UnitBSCIcon,
        valueFormatted: formatValue(CURRENCY_CRYPTO, offer?.price_value)
      }
      const priceMATIC = {
        symbol: 'Matic',
        icon: UnitMaticIcon,
        valueFormatted: formatValue(CURRENCY_CRYPTO, offer?.price_value)
      }
      const priceTON = {
        symbol: 'TON',
        icon: UnitTONIcon,
        valueFormatted: formatValue(CURRENCY_CRYPTO, offer?.price_value)
      }

      if (offer?.price_currency_native?.name === 'ETH') {
        return priceETH
      }
      if (offer?.price_currency_native?.name === 'BNB') {
        return priceBNB
      }
      if (offer?.price_currency_native?.name === 'MATIC') {
        return priceMATIC
      }
      if (offer?.price_currency_native?.name === 'TON') {
        return priceTON
      }
      return priceETH
    })()

  const baseSymbol = Boolean(networks.length) && networks[Number(currentNetworkIndex)]?.currency_symbol

  // todo: crosstype + list
  const isUnlistedAddress = Boolean(offer?.can_mint && wallet.address && !offer.can_mint.toLowerCase().includes(wallet.address.toLowerCase()))

  const logo = offer?.merchant_logo_url || offer?.merchant?.logo_url

  const isSoldOut = offer && (!offer.active || offer.total_amount > 0 && offer.minted_amount >= offer.total_amount /*&& !offer.hide_minted_amount*/)

  const iconOfNetworkId = {
    1: NetworkEthereumIcon,
    4: NetworkEthereumIcon,
    56: NetworkBscIcon,
    97: NetworkBscIcon,
    137: NetworkPolygonIcon,
    80001: NetworkPolygonIcon,
    66: NetworkOecIcon,
    65: NetworkOecIcon,
    42161: NetworkArbitrumIcon,
    421611: NetworkArbitrumIcon,
    43114: NetworkAvalancheIcon,
    43113: NetworkAvalancheIcon,
    250: NetworkFantomIcon,
    4002: NetworkFantomIcon,
    10: NetworkOptimismIcon,
    69: NetworkOptimismIcon,
    '-1': NetworkSolanaIcon,
    '-1001': NetworkSolanaIcon,
    '-3': NetworkTonIcon,
    '-1003': NetworkTonIcon
  }

  const crosstypeNetworkIcon = (offer && iconOfNetworkId[offer.chain_id])
    ? iconOfNetworkId[offer.chain_id]
    : NetworkUnknownIcon

  const nftCard = (
    <div className="nftCard">
      <div className="nftImageBox">
        {!offer?.image_url ?
          <div className="nftImageSkeleton">
            <Skeleton w="100%" h="100%" r={16} />
          </div>
          :
          <>
            <div className="nftImageUnderlay" style={{ backgroundImage: `url(${offer?.image_url})` }} />
            <div className="nftImage" style={{ backgroundImage: `url(${offer?.image_url})` }} />
          </>
        }
      </div>
      <div className="nftPrices">
        <div className={`nftPrice nftPriceMain`}>
          {nftPrice ?
            <>
              <img src={nftPrice.icon} alt={nftPrice.symbol} title={nftPrice.symbol} />
              <div>{nftPrice.valueFormatted}</div>
            </>
            :
            <Skeleton w={65} h={20} />
          }
        </div>
        <div className="nftPricesOther">
          <div className="nftPrice">
            {nftUsdPrice ?
              <>
                <span className="nftPriceUsd">
                  <span className="unit">$</span>
                  <span className="value">{formatValue(CURRENCY_USD, nftUsdPrice)}</span>
                </span>
              </>
              :
              <Skeleton w={44} h={16} />
            }
          </div>
        </div>
      </div>
    </div>
  )

  if (isOfferNotFound) {
    return Page404()
  }

  return (
    <>
      <div className="sides">
        <div className="sideLeft">
          {nftCard}
        </div>
        <div className="sideRight">
          <Header
            logo={logo}
            acceptNetworks={supportedNetworks ? supportedNetworks.map(item => item.chain_id) : null}
            onClickConnect={() => { setSelectWalletOpen(true) }}
          />

          <div id="main" className="main">
            <div className="pageContent">
              {status === null &&
                nftCard
              }

              {status === null &&
                <div className="mainForm">
                  {!(offer && !logo) &&
                    <div className="merchantLogo">
                      {logo ?
                        <img src={logo} alt="Logo" />
                        :
                        <Skeleton w={110} h={36} />
                      }
                    </div>
                  }

                  <div className="nftMintedAndTitle">
                    {!isSoldOut &&
                      <div className="nftMinted">
                        {!offer
                          ? <Skeleton w={120} h={20} />
                          : offer.hide_minted_amount
                            ? null
                            : offer.total_amount
                              ? `Minted ${offer.minted_amount >= 0 ? offer.minted_amount : '?'}/${offer.total_amount}`
                              : null
                        }
                      </div>
                    }
                    <div className={`nftTitle ${isSoldOut ? 'soldOut' : ''}`}>{offer?.name || <Skeleton w={180} h={36} />}</div>
                    {isSoldOut &&
                      <div className="nftSoldOut">Sold out!</div>
                    }
                  </div>

                  {!isSoldOut &&
                    <div className={`payControls ${isCrosstype ? 'crosstype' : 'notCrosstype'} ${isCrosstypeRecipientFocused ? 'focused' : ''}`}>
                      <div className={`coin ${isCrosstype ? 'crosstype' : 'notCrosstype'}`}>
                        <div>
                          {(currentToken && price !== undefined)
                            ? <p className="tokenSum">{formatValue(currentToken, price)}</p>
                            : <Skeleton w={85} h={22} />
                          }
                        </div>

                        <div className="coinSelectWrapper">
                          {(!offer || !supportedTokens || !currentToken) &&
                            <div className="coinSelect coinSelectLoading">
                              <Skeleton w={24} h={24} />
                              &nbsp;&nbsp;
                              <Skeleton w={54} h={21} />
                            </div>
                          }
                          {offer && supportedTokens && currentToken &&
                            <a className={`coinSelect ${offer && supportedTokens && 'coinSelectActive'}`} onClick={() => {
                              if (supportedTokens) {
                                setSelectTokenOpen(prev => !prev)
                              }
                            }}>
                              <div className="currentToken">
                                <img className="currentTokenIcon" src={currentToken?.data?.logo_url || CoinUnknownIcon} width="24" height="24" alt="⬤" />
                              </div>
                              <p className="tokenTitle">{currentToken?.data?.symbol}</p>
                              <IconSelect />
                            </a>
                          }
                        </div>
                      </div>

                      {isCrosstype &&
                        <>
                          <div className="crosstypeRecipientWrapper">
                            <hr className="crosstypeRecipientDivider" />
                            <div className="crosstypeNetwork">
                              <img className="crosstypeNetworkIcon" src={crosstypeNetworkIcon} alt="" width="20" height="20" />
                              <img className="addAddressIcon" src={AddIcon} alt="+" width="14" height="14" />
                            </div>
                            <input
                              type="text"
                              className={`crosstypeRecipient ${isCrosstypeRecipientValid ? '' : 'error' }`}
                              placeholder={`Add ${networks ? networks.find(net => net.chain_id === offer.chain_id)?.short_name : ''} wallet address`}
                              value={crosstypeRecipient}
                              onChange={(e) => {
                                setCrosstypeRecipient(e.target.value)}
                              }
                              onFocus={() => { setCrosstypeRecipientFocused(true) }}
                              onBlur={() => { setCrosstypeRecipientFocused(false) }}
                            />
                            <button
                              type="button"
                              className="paste"
                              onClick={async () => {
                                const text = await navigator.clipboard.readText()
                                setCrosstypeRecipient(text)
                              }}
                            >
                              Paste
                            </button>
                            <div className={`addressError ${!isCrosstypeRecipientValid ? 'visible' : ''}`}>It doesn't look right.</div>
                            <div className={`walletLink ${isCrosstypeRecipientValid && (offer?.chain_id === -3 || offer?.chain_id === -1003) ? 'visible' : ''}`}>
                              <a href="https://wallet.ton.org/" target="_blank" rel="noreferrer">
                                <IconAdd />
                                <span>Create TON wallet</span>
                              </a>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  }

                  {false && !isHideTxCost &&
                    <div className="txFee">
                      <div className="flex">
                        <p className="legendTitle">Tx cost</p>
                        <a data-tip="..." data-for="tip-1" className="question">?</a>
                        <ReactTooltip id="tip-1">
                          <div className="tip">Transaction cost is the gas price is determined by an auction-type mechanism, where the miners look for the highest fees attached to a transaction, then process these transactions from there in descending order.</div>
                        </ReactTooltip>
                      </div>
                      <div className="flex">
                        {!offer || !networks.length || currentNetworkIndex === null || !gasCostBase || !gasCostUSD ? (
                            <Skeleton w={130} h={19} />
                          ) : (
                            <p className="legendLabel">
                              <span>
                                <span className="value">{
                                  formatValue(CURRENCY_BASE, gasCostBase)
                                }</span>
                                &nbsp;
                                <span className="unit">{baseSymbol}</span>
                              </span>
                              <span className="legendLabelLight">
                                <span className="unit">$</span>
                                <span className="value">{
                                  formatValue(CURRENCY_USD, gasCostUSD)
                                }</span>
                              </span>
                            </p>
                          )
                        }
                      </div>
                    </div>
                  }

                  {!isSoldOut &&
                    <div className="formFooter">
                      {!offer ?
                        <Skeleton w={120} h={48} r={12} />
                        :
                        offer.max_amount_per_transaction === 1 ?
                          null
                          :
                          <div className="quantity">
                            <button
                              className="quantityButton"
                              disabled={
                                !offer ||
                                quantity <= 1 ||
                                !price ||
                                waitingSubmit ||
                                successSubmit
                              }
                              onClick={() => { setQuantity(quantity - 1) }}
                            >−</button>
                            <input
                              type="text"
                              disabled={
                                !offer ||
                                !price ||
                                waitingSubmit ||
                                successSubmit
                              }
                              value={quantityInputValue === 0 ? '' : quantityInputValue}
                              className="quantityValue"
                              onChange={(e) => {
                                const input = e.target.value
                                //console.log('change input', input)
                                if (input === '') {
                                  setQuantityInputValue(0)
                                  return
                                }
                                const intValue = parseInt(input.replace(/\D/g,''))
                                if (!intValue) {
                                  return
                                }
                                //console.log('change input filtered', intValue)
                                if (intValue > 99) {
                                  return
                                }
                                let newValue = 1
                                if (intValue >= 1) {
                                  newValue = intValue
                                }
                                setQuantityInputValue(newValue)
                              }}
                              onBlur={(e) => {
                                const value = e.target.value
                                //console.log('blur value', value)
                                const applyValue = value || 1
                                //console.log('blur applyValue', applyValue)

                                const limitPerTx = offer.max_amount_per_transaction // number
                                const limitLeft = offer.total_amount === 0
                                  ? undefined
                                  : offer.total_amount - offer.minted_amount // number, number

                                const limit = limitLeft === undefined ? limitPerTx : Math.min(limitPerTx, limitLeft)

                                const applyValueLimited = applyValue <= limit ? applyValue : limit
                                //console.log('blur applyValueLimited', applyValueLimited)

                                if (quantity !== applyValueLimited) {
                                  setQuantity(applyValueLimited)
                                } else {
                                  setQuantityInputValue(applyValueLimited)
                                }
                              }}
                            />
                            <button
                              className="quantityButton"
                              disabled={
                                !offer ||
                                offer.total_amount && quantity >= offer.total_amount - offer.minted_amount ||
                                offer.max_amount_per_transaction && quantity >= offer.max_amount_per_transaction ||
                                !price ||
                                waitingSubmit ||
                                successSubmit
                              }
                              onClick={() => { setQuantity(quantity + 1) }}
                            >+</button>
                          </div>
                      }

                      <button
                        className={`
                          submit
                          ${!wallet.address && 'unconnected'}
                          ${successSubmit && 'success'}
                        `}
                        disabled={
                          !offer ||
                          (wallet.address && !price) ||
                          (wallet.address && !supportedTokens) ||
                          (currentToken && !price) ||
                          (wallet.address && !currentToken) ||
                          (wallet.address && (!isEnoughCurrentToken || !isEnoughBaseCurrency)) ||
                          (wallet.address && !toAddress) ||
                          quantity === 0 ||
                          isWrongNetwork ||
                          isUnlistedAddress ||
                          isCrosstype && !crosstypeRecipient ||
                          waitingSubmit ||
                          successSubmit
                        }
                        onClick={() => {
                          if (!wallet.address) {
                            setSelectWalletOpen(true)
                          } else {
                            submit()
                          }
                        }}
                      >
                        {successSubmit
                          ? 'Success'
                          : (
                            !offer ||
                            (wallet.address && !supportedTokens) ||
                            (currentToken && !price) ||
                            waitingSubmit
                          )
                            ? 'Loading...'
                            : !wallet.address
                              ? 'Connect wallet'
                              : isWrongNetwork
                                ? 'Wrong network'
                                : isUnlistedAddress
                                  ? 'You are not listed'
                                  : !currentToken
                                    ? 'Select a token'
                                    : !isEnoughCurrentToken
                                      ? `Low ${currentToken?.data?.name || ''} balance`
                                      : !isEnoughBaseCurrency
                                        ? `Low ${baseSymbol || ''} balance`
                                        : `Mint`
                        }
                        {waitingSubmit && (
                          <img className="submitLoaderIcon" src={SubmitLoaderIcon} alt="⏱️" />
                        )}
                        {successSubmit && (
                          <img className="submitSuccessIcon" src={SubmitSuccessIcon} alt="✔️" />
                        )}
                      </button>
                    </div>
                  }
                </div>
              }

              {status !== null &&
                <div className="statusPanel">
                  <Indicator status={status} />
                  <div className="statusText">
                    <div className="statusTitle">
                      {status === 'wait' && 'Awaiting confirmation'}
                      {status === 'success' && 'Now it\'s yours!'}
                      {status === 'error' && 'Something went wrong'}
                    </div>
                    {status === 'error' &&
                      <div className="statusErrorDescription">{statusErrorDescription}</div>
                    }
                    {status === 'success' && mintTxHash &&
                      <a
                        className="statusTxLink"
                        href={networks.find(net => net.chain_id === offer.chain_id)?.explorer_url + `/tx/${mintTxHash}`}
                        target="_blank"
                      >View transaction</a>
                    }
                  </div>
                  <div className="statusNftTitle">
                    {quantity > 1 &&
                      <span className="statusNftQuantity">{quantity} &times; </span>
                    }{offer?.name}</div>
                  <div className="statusTokenSum">
                    {(currentToken && price !== undefined)
                      ? <span>{formatValue(currentToken, price)}</span>
                      : <Skeleton w={85} h={20} />
                    }
                    &nbsp;
                    <span>{currentToken?.data?.symbol}</span>
                  </div>
                  <div className="statusFooter">
                    {(status === 'error' || status === 'success' && !redirectUrl) &&
                      <a className="statusBack" href={'#'} onClick={(e) => {
                        e.preventDefault()
                        setStatus(null)
                        setStatusErrorDescription(null)
                      }}>
                        <IconBack />
                        <span>Back</span>
                      </a>
                    }
                    {status === 'success' && redirectUrl &&
                      <TimerButton className="statusBack" timeout={15} href={redirectUrl}>
                        <>
                          <IconBack />
                          <span>Back to Website</span>
                        </>
                      </TimerButton>
                    }
                    {status === 'success' &&
                      <div className="statusSocials">
                        <a className="twButton" href="#" onClick={(e) => {
                          e.preventDefault()
                          let shareURL = 'http://twitter.com/share?'
                          const params = {
                            url: window.location.href,
                            text: 'My new NFT',
                            //via: 'sometwitterusername',
                            hashtags: 'nft,viaprotocol'
                          }
                          for (let prop in params) {
                            shareURL += '&' + prop + '=' + encodeURIComponent(params[prop])
                          }
                          window.open(shareURL, '', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
                        }}>
                          <IconTw />
                        </a>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>

            {/* modalboxes */}
            {(isSelectWalletOpen && !wallet.isConnected && offer) && (
              <SelectWallet close={() => { setSelectWalletOpen(false) }} />
            )}

            {isSelectTokenOpen && (
              <SelectToken close={() => setSelectTokenOpen(false)} />
            )}

            {isConfirmModalboxOpen && (
              <ConfirmTx isSuccess={isConfirmModalboxSuccess} close={() => setConfirmModalboxOpen(false)} />
            )}
          </div>
          <Footer />
        </div>
        <div className="openDebug" onClick={() => { setDebugOpen(!isDebugOpen) }}></div>
      </div>

      {isDebugOpen &&
        <Debug />
      }
    </>
  )
}

export default App
