export const IconVia = () =>
  <svg width="60" height="13" viewBox="0 0 60 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50.6812 0L41.3633 13L50.6812 7.8L59.9991 13L50.6812 0Z" fill="currentColor"/>
    <path d="M9.31791 13L18.6358 0L9.31791 5.2L0 0L9.31791 13Z" fill="currentColor"/>
    <path d="M30.8734 13H27.377L29.1252 0H32.6216L30.8734 13Z" fill="currentColor"/>
  </svg>

export const IconSelect = () =>
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.00019 7.00019C5.86858 7.00095 5.73812 6.97572 5.61628 6.92596C5.49445 6.87619 5.38363 6.80287 5.29019 6.71018L1.29019 2.71018C1.10188 2.52188 0.996094 2.26649 0.996094 2.00019C0.996094 1.73388 1.10188 1.47849 1.29019 1.29019C1.47849 1.10188 1.73389 0.996094 2.00019 0.996094C2.26649 0.996094 2.52188 1.10188 2.71019 1.29019L6.00019 4.59019L9.29019 1.29019C9.47849 1.10188 9.73388 0.996094 10.0002 0.996094C10.2665 0.996094 10.5219 1.10188 10.7102 1.29019C10.8985 1.47849 11.0043 1.73388 11.0043 2.00019C11.0043 2.26649 10.8985 2.52188 10.7102 2.71018L6.71019 6.71018C6.61675 6.80287 6.50593 6.87619 6.38409 6.92596C6.26225 6.97572 6.13179 7.00095 6.00019 7.00019Z" fill="currentColor" />
  </svg>

export const IconAdd = () =>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 0H3V3H0V5H3V8H5V5H8V3H5V0Z" fill="currentColor"/>
  </svg>

export const IconBack = () =>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.00078 11.5009C7.27078 11.2309 7.27078 10.7809 7.00078 10.5109L3.19078 6.71086H10.5008C10.8908 6.71086 11.2008 6.40086 11.2008 6.01086C11.2008 5.62086 10.8908 5.31086 10.5008 5.31086H3.19078L7.00078 1.51086C7.27078 1.24086 7.27078 0.790859 7.00078 0.520859C6.73078 0.250859 6.28078 0.250859 6.01078 0.520859L1.00078 5.50086C0.940781 5.56086 0.880781 5.64086 0.850781 5.73086C0.810781 5.82086 0.800781 5.91086 0.800781 6.00086C0.800781 6.09086 0.820781 6.18086 0.850781 6.27086C0.890781 6.36086 0.940781 6.43086 1.00078 6.50086L6.00078 11.5009C6.27078 11.7709 6.72078 11.7709 6.99078 11.5009H7.00078Z" fill="currentColor" />
  </svg>

export const IconTw = () =>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 2.32222C19.2513 2.68003 18.4568 2.91448 17.6433 3.01769C18.5005 2.46593 19.1418 1.59701 19.4475 0.573269C18.6421 1.08819 17.7608 1.45099 16.8418 1.64598C16.2232 0.937869 15.4051 0.46902 14.514 0.311981C13.623 0.154943 12.7087 0.318469 11.9126 0.777258C11.1166 1.23605 10.4832 1.96452 10.1104 2.84996C9.7376 3.7354 9.64622 4.72844 9.85039 5.67543C8.21882 5.58748 6.62267 5.13106 5.16556 4.33581C3.70844 3.54056 2.42295 2.42425 1.39253 1.05936C0.868 2.03212 0.707351 3.1835 0.943261 4.27927C1.17917 5.37504 1.79392 6.33289 2.66244 6.95797C2.01084 6.93571 1.37362 6.74612 0.803849 6.405C0.803443 6.42336 0.803443 6.44216 0.803443 6.46095C0.803788 7.48104 1.13172 8.46962 1.73164 9.25913C2.33157 10.0486 3.16659 10.5905 4.09516 10.7929C3.74273 10.8958 3.37918 10.9478 3.01403 10.9476C2.75487 10.9477 2.4963 10.921 2.24184 10.8681C2.50397 11.7459 3.01434 12.5135 3.70157 13.0636C4.38881 13.6138 5.21854 13.9189 6.07474 13.9363C4.35334 15.3852 2.16978 16.0428 0 15.7657C1.87608 17.064 4.05974 17.7531 6.28991 17.7507C13.8376 17.7507 17.9644 11.0189 17.9644 5.1806C17.9644 4.98914 17.9606 4.79841 17.953 4.6084C18.7565 3.98384 19.4497 3.20959 20 2.32222V2.32222Z" fill="currentColor" />
  </svg>

export const IconExplorer = () =>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5008 8.7998C12.1108 8.7998 11.8008 9.1098 11.8008 9.4998V11.4998C11.8008 12.7698 10.7708 13.7998 9.50078 13.7998H4.50078C3.23078 13.7998 2.20078 12.7698 2.20078 11.4998V6.4998C2.20078 5.2298 3.23078 4.1998 4.50078 4.1998H6.50078C6.89078 4.1998 7.20078 3.8898 7.20078 3.4998C7.20078 3.1098 6.89078 2.7998 6.50078 2.7998H4.50078C2.46078 2.7998 0.800781 4.4598 0.800781 6.4998V11.4998C0.800781 13.5398 2.46078 15.1998 4.50078 15.1998H9.50078C11.5408 15.1998 13.2008 13.5398 13.2008 11.4998V9.4998C13.2008 9.1098 12.8908 8.7998 12.5008 8.7998Z" fill="currentColor" />
    <path d="M15.1514 1.23004C15.1214 1.15004 15.0614 1.07004 15.0014 1.00004C15.0014 1.00004 14.9614 0.980039 14.9514 0.960039C14.9014 0.910039 14.8414 0.870039 14.7714 0.840039C14.6814 0.800039 14.5914 0.790039 14.5014 0.790039H9.50141C9.11141 0.790039 8.80141 1.10004 8.80141 1.49004C8.80141 1.88004 9.11141 2.19004 9.50141 2.19004H12.8114L6.58141 8.42004C6.31141 8.69004 6.31141 9.14004 6.58141 9.41004C6.72141 9.55004 6.90141 9.62004 7.08141 9.62004C7.26141 9.62004 7.44141 9.55004 7.58141 9.41004L13.7914 3.20004V6.48004C13.7814 6.87004 14.0914 7.18004 14.4714 7.18004C14.8614 7.18004 15.1714 6.87004 15.1714 6.48004L15.1914 1.50004C15.1914 1.41004 15.1714 1.32004 15.1414 1.23004H15.1514Z" fill="currentColor" />
  </svg>

export const IconQuestion = () =>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6568 13.6568C15.105 12.2086 16 10.2094 16 8.00114C16 5.79067 15.105 3.79146 13.6568 2.34318C12.2086 0.894908 10.2094 0 8.00114 0C5.79067 0 3.79146 0.894965 2.34318 2.34318C0.894908 3.7914 0 5.79059 0 8.00114C0 10.2094 0.894965 12.2085 2.34318 13.6568C3.7914 15.1051 5.79059 16 8.00114 16C10.2094 16 12.2085 15.105 13.6568 13.6568ZM7.18943 5.61745C7.18943 5.98396 6.89032 6.28307 6.52158 6.28307C6.15507 6.28307 5.85596 5.98396 5.85596 5.61745C5.85596 4.43465 6.81847 3.47215 8.00126 3.47215C9.18182 3.47215 10.1443 4.43465 10.1443 5.61745C10.1443 6.77558 9.64733 7.34443 9.17736 7.87749C8.91431 8.1766 8.66688 8.45763 8.66688 8.86019C8.66688 9.22893 8.36777 9.52805 8.00126 9.52805C7.63252 9.52805 7.33341 9.22893 7.33341 8.86019C7.33341 7.963 7.74266 7.49526 8.17671 7.00272C8.48474 6.65195 8.81085 6.28084 8.81085 5.61747C8.81085 5.16771 8.4488 4.80565 8.00126 4.80565C7.55149 4.80565 7.18943 5.16771 7.18943 5.61747V5.61745ZM8.00126 10.5286C8.55225 10.5286 8.99968 10.9761 8.99968 11.527C8.99968 12.0802 8.55214 12.5276 8.00126 12.5276C7.44803 12.5276 7.00061 12.0801 7.00061 11.527C7.00061 10.976 7.44815 10.5286 8.00126 10.5286Z" fill="currentColor"/>
  </svg>
