import React from 'react'


const Indicator = ({ status = 'wait' }) => {
  return (
    <div className={`Indicator status-${status}`}>
      <div className="stick" />
      <div className="stick" />
      <div className="stick" />
      <div className="stick" />
      <div className="stick" />
      <div className="stick" />
      <div className="stick" />
      <div className="stick" />
    </div>
  )
}

export default Indicator
