import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'


const Copy = ({ text, children }) => {
  const [isCopied, setCopied] = useState(false)
  let timer: any = null

  return (
    <div className="Copy">
      <CopyToClipboard
        text={text}
        onCopy={() => {
          if (timer) {
            clearTimeout(timer)
          }
          setCopied(true)
          timer = setTimeout(() => {
            setCopied(false)
            clearTimeout(timer)
          }, 900)
        }}
      >
        {children}
      </CopyToClipboard>
      <div className={`copied ${isCopied ? 'active' : ''}`}>Copied!</div>
    </div>
  )
}

export default Copy
