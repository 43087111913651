import React from 'react'

import { IconVia } from './components/Icons'

const Page404 = () => {
  return (
    <div className="page404">
      <div className="Header">
      </div>
      <div className="main" id="main">
        <div className="sheet">
          <div className="title">Error 404</div>
          <div className="subtitle">Page not found</div>
        </div>
      </div>
      <footer>
        <a href="https://via.exchange" target="_blank" rel="noreferrer">
          <IconVia />
        </a>
      </footer>
    </div>
  )
}

export default Page404
