/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'

// icons
import CloseIcon from '../images/close.svg'
import CoinUnknownIcon from '../images/coin-unknown.svg'

import NetworkSolanaIcon from '../images/network-solana.svg'
import NetworkBscIcon from '../images/network-bsc.svg'
import NetworkEthereumIcon from '../images/network-ethereum.svg'
import NetworkOecIcon from '../images/network-oec.svg'
import NetworkPolygonIcon from '../images/network-polygon.svg'
import NetworkArbitrumIcon from '../images/network-arbitrum.svg'
import NetworkAvalancheIcon from '../images/network-avalanche.svg'
import NetworkFantomIcon from '../images/network-fantom.svg'
import NetworkOptimismIcon from '../images/network-optimism.svg'
import NetworkUnknownIcon from '../images/network-unknown.svg'

// utils
import { Context } from '../Context'
import { WalletContext } from './../web3-wallets'
import { formatValue } from '../format-crypto/format'


const SelectWallet = ({ close }) => {
  const wallet = useContext(WalletContext)
  const {
    supportedTokens,
    currentToken, setCurrentToken,
    currentNetworkIndex,
    networks,
    setCurrentNetworkIndex,
    getBalance
  } = useContext(Context)

  const iconOfNetworkId = {
    '-1': NetworkSolanaIcon,
    1: NetworkEthereumIcon,
    4: NetworkEthereumIcon,
    56: NetworkBscIcon,
    97: NetworkBscIcon,
    137: NetworkPolygonIcon,
    80001: NetworkPolygonIcon,
    66: NetworkOecIcon,
    65: NetworkOecIcon,
    42161: NetworkArbitrumIcon,
    421611: NetworkArbitrumIcon,
    43114: NetworkAvalancheIcon,
    43113: NetworkAvalancheIcon,
    250: NetworkFantomIcon,
    4002: NetworkFantomIcon,
    10: NetworkOptimismIcon,
    69: NetworkOptimismIcon,
  }

  return (
    <div className="cardBg">
      <div className="card cardSelect cardSelectToken">
        <div className="cardHeader">
          <a className="close" onClick={close}>
            <img src={CloseIcon} alt="❌" />
          </a>
          <p className="cardTitle">Select a token</p>
        </div>
        <div className="cardMain">
          <div className="tokens">
            {supportedTokens && supportedTokens.map((token, i) => {
              const networkIcon = iconOfNetworkId[token.chain_id]
                ? iconOfNetworkId[token.chain_id]
                : NetworkUnknownIcon

              const tokenBalance = getBalance({
                chainId: token.chain_id,
                tokenAddress: token.address,
              })

              const tokenNetworkName = networks.find(n => n.chainID === token.chain_id).short_name

              return (
                <div
                  key={`token_${i}`}
                  className="tokenItem"
                  onClick={async () => {
                    if (
                      token.chain_id === currentToken?.chain_id
                      && token.address === currentToken?.address
                    ) {
                      close()
                      return
                    }

                    console.log('currentToken', currentToken)
                    console.log('SelectToken', token.chain_id, token.address)
                    const currentNetwork = networks[Number(currentNetworkIndex)]

                    if (currentNetwork.chain_id !== token.chain_id) {
                      const newNetworkIndex = networks.findIndex(n => n.chainID === token.chain_id)
                      if (!wallet.isConnected) {
                        setCurrentNetworkIndex(newNetworkIndex)
                      } else {
                        const newChainId = token.chain_id
                        const success = await wallet.changeNetwork(newChainId)
                        if (success) {
                          setCurrentNetworkIndex(newNetworkIndex)
                        } else {
                          return
                        }
                      }
                    }

                    console.log('setCurrentToken', token)
                    setCurrentToken(token)
                    close()
                  }
                }>
                  <div className="flex">
                    <div className="tokenIconWrapper">
                      <img className="tokenIcon" src={token?.data?.logo_url || CoinUnknownIcon} width="24" height="24" alt="⚫" />
                    </div>
                    <div className="tokenText">
                      <div className="tokenTextTop">
                        <p className="tokenName" title={token?.data?.fullName}>{token?.data?.symbol}</p>
                      </div>
                      <div className="tokenTextBottom">
                        <img className="tokenNetworkIcon" src={networkIcon} alt="🔳" height="12" width="12" />
                        <p className="tokenNetworkName">{tokenNetworkName}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="tokenValue">{wallet.isConnected && formatValue(token, tokenBalance)}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectWallet
