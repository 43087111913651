import React, { useEffect } from 'react'

const TimerButton = ({ className, timeout, onClick, href, children }: {
  className?: string
  timeout: number
  onClick?: Function
  href?: string
  children?: React.ReactNode
}) => {
  let timer

  const fire = () => {
    if (typeof onClick === 'function') {
      onClick()
    }
    if (href) {
      window.location.href = href
    }
  }

  useEffect(() => {
    timer = setTimeout(() => {
      fire()
    }, timeout * 1e3)
  }, [])

  const onClickClearTimer = () => {
    clearTimeout(timer)
    fire()
  }

  const inner = (
    <>
      {children}
      <div className="progress" style={{ animationDuration: `${timeout}s` }} />
    </>
  )

  return href
    ? <a className={`TimerButton ${className}`} onClick={onClickClearTimer} href={href}>{inner}</a>
    : <button className={`TimerButton ${className}`} onClick={onClickClearTimer} type="button">{inner}</button>
}

export default TimerButton
