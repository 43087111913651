const customApiUrl = process.env.REACT_APP_API_URL
const customExplorerApiUrl = process.env.REACT_APP_EXPLORER_API_URL

const defaultApiUrl = 'https://checkout-api.via.exchange/v1'
//const defaultApiUrl = 'https://checkout-staging-api.via.exchange/v1'

const defaultExplorerApiUrl = 'https://explorer-api.via.exchange/v1'
//const defaultExplorerApiUrl = 'https://explorer-staging-api.via.exchange/v1'

export const apiUrl = customApiUrl || defaultApiUrl
export const explorerApiUrl = customExplorerApiUrl || defaultExplorerApiUrl
