import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import reportWebVitals from './_reportWebVitals'
import ContextProvider from './Context'
import WalletProvider from './web3-wallets'

import './styles.sass'

// pages
import App from './App'
import Costs from './demo/Costs'
import WC from './demo/wc'
import Page404 from './404'


if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://1d0837f9569e45bc86bbb29fc2f6af6e@o369541.ingest.sentry.io/6142232',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const urlParams = new URLSearchParams(window.location.search)
const isDark = urlParams.get('dark')
if (isDark) {
  document.documentElement.setAttribute('data-theme', 'dark')
}


const RootRedirect = () => {
  window.location.replace('https://viaexchange.notion.site/viaexchange/Cross-chain-NFT-sale-and-minting-062f73d68a4d44e08b4ae5ea49f9a2f5')
  return null
}

ReactDOM.render(
  <React.StrictMode>
    <WalletProvider>
      <ContextProvider>
        <Router>
          <Routes>
            <Route path="/nft/:id" element={<App />} />
            <Route path="/costs" element={<Costs />} />
            <Route path="/wc" element={<WC />} />
            <Route path="/" element={<RootRedirect />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Router>
      </ContextProvider>
    </WalletProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()


console.info(`

#         #    #    #
 ##     ##    #    ###
  ### ###    #    #####
   #####    #    ### ###
    ###    #    ##     ##
     #    #    #         #

We are hiring!
https://viaexchange.notion.site/Work-at-VIA-dfb1c4052c6242969effcb381bdc91ea

`)
