import React, { useState, useEffect, useContext } from 'react'

import { Context } from '../Context'
import { WalletContext } from './../web3-wallets'

// icons
//import SelectArrow from '../images/selectArrow.svg'

import MetamaskIcon from '../images/wallet-metamask.svg'
import WalletConnectIcon from '../images/wallet-walletconnect.svg'
import PhantomIcon from '../images/wallet-phantom.svg'
import UnknownWalletIcon from '../images/wallet-unknown.svg'

//import WrongNetworkIcon from '../images/wrong-network.png'
//import SelectArrowWhite from '../images/selectArrowWhite.svg'

import CopyIcon from '../images/account-copy.svg'
//import CopySuccessIcon from '../images/account-copy-success.svg'
import LinkIcon from '../images/account-link.svg'
import LogoutIcon from '../images/account-logout.svg'

// components
//import SelectNetwork from './SelectNetwork'
import Skeleton from './Skeleton'
import Copy from './Copy'

// utils
import { formatAddress, formatValue, CURRENCY_CRYPTO } from '../format-crypto/format'

const Header = ({ logo, acceptNetworks, onClickConnect }) => {
  const wallet = useContext(WalletContext)
  const {
    offer,
    currentNetworkIndex,
    networks,
    accountBalance,
    //isWrongNetwork
  } = useContext(Context)

  //const [isSelectNetworkOpen, setSelectNetworkOpen] = useState(false)
  const [isAddressDropdownOpen, setAddressDropdownOpen] = useState(false)

  useEffect(() => {
    document.addEventListener('click', (event) => {
      const target = event.target as Node
      const box = document.getElementById('navSelect')
      const boxWrongNetwork = document.getElementById('wrongNetworkSelect')
      if (
        (box && !box.contains(target)) ||
        (boxWrongNetwork && !boxWrongNetwork.contains(target))
      ) {
        //setSelectNetworkOpen(false)
      }

      const authWrapper = document.getElementById('authWrapper')
      if (authWrapper && !authWrapper.contains(target)) {
        setAddressDropdownOpen(false)
      }
    })
  }, [])

  const WalletIcon = wallet.name
    ? {
        MetaMask: MetamaskIcon,
        WalletConnect: WalletConnectIcon,
        Phantom: PhantomIcon
      }[wallet.name] || UnknownWalletIcon
    : UnknownWalletIcon

  return (
    <div className="Header">
      {!(offer && !logo) ?
        <div className="merchantLogo">
          {logo ?
            <img src={logo} alt="Logo" />
            :
            <Skeleton w={110} h={40} />
          }
        </div>
        :
        <div className="merchantLogo">&nbsp;</div>
      }
      <div className="userInfo">
        {/*{!isWrongNetwork &&
          ((!offer || currentNetworkIndex === null) ?
            <Skeleton w={window.innerWidth <= 768 ? 56 : 141} h={40} />
            :
            <div
              id="navSelect"
              className="headerSelect"
              onClick={(e) => { setSelectNetworkOpen(prev => !prev) }}
            >
              {networks &&
                <div className="flex">
                  <img className="navSelectIcon" src={networks[currentNetworkIndex]?.icon} alt="⬤" />
                  <div
                    className="tokenSelectName">
                    {networks[currentNetworkIndex]?.name}
                  </div>
                </div>
              }
              <img src={SelectArrow} className="selectArrow" alt="🡇" />
              {isSelectNetworkOpen &&
                <SelectNetwork acceptNetworks={acceptNetworks} />
              }
            </div>
          )
        }*/}

        {/*{isWrongNetwork &&
          <div
            id="wrongNetworkSelect"
            className="wrongNetwork"
            onClick={(e) => {
              setSelectNetworkOpen(prev => !prev)
            }}
          >
            <div className="wrongNetworkInner">
              <img className="wrongNetworkIcon" src={WrongNetworkIcon} width="24" height="24" alt="⚠️" />
              <span>Wrong Network</span>
              <img src={SelectArrowWhite} className="selectArrow" alt="🡇" />
            </div>
            {isSelectNetworkOpen &&
              <SelectNetwork acceptNetworks={acceptNetworks} />
            }
          </div>
        }*/}

        {!wallet.address &&
          <button className="navButton" onClick={onClickConnect}>
            Connect wallet
          </button>
        }

        {wallet.address && /*!isWrongNetwork &&*/
          <div className="authWallet" id="authWrapper">
            <div className="addressContainer" onClick={(e) => { setAddressDropdownOpen(prev => !prev) }}>
              <div className="balanceContainer">
                <img src={WalletIcon} width="24" height="24" alt="👛" />
                <p className="balance">
                  {/*<span>{wallet.balance}</span>
                  &nbsp;*/}
                  <span>{formatValue(CURRENCY_CRYPTO, accountBalance)}</span>
                  &nbsp;
                  <span>{networks[Number(currentNetworkIndex)]?.data.params[0].nativeCurrency.symbol}</span>
                  </p>
              </div>
              <p className="address">
                {wallet.addressDomain || formatAddress(wallet.address)}
              </p>
            </div>

            {/* account/address dropdown */}
            {isAddressDropdownOpen &&
              <div className="addressDropdown">
                <div className="connected">Connected</div>
                <div className="flex">
                  <div className="accountAvatar">
                    <img src={WalletIcon} className="accountAvatarIcon" width="30" height="30" alt="🔵" />
                  </div>
                  <div className="addressInfo">
                    <div className="walletName">{wallet.name}</div>
                    <div className="addressValue">{wallet.addressDomain || formatAddress(wallet.address)}</div>
                  </div>
                  <div className="buttons">
                    <Copy text={wallet.address}>
                      <button className={`button buttonCopy`} title="Copy">
                        <img src={CopyIcon} alt="📋" />
                      </button>
                    </Copy>
                    <button
                      className={`button buttonLink`}
                      title="Explorer"
                      disabled={!wallet.address || !networks.length || currentNetworkIndex === null || !networks[currentNetworkIndex]?.explorer_url}
                      onClick={() => {
                        const explorerUrl = networks[Number(currentNetworkIndex)]?.explorer_url
                        const url = `${explorerUrl}/address/${wallet.address}`
                        window.open(url)
                      }}
                    >
                      <img src={LinkIcon} alt="↗" />
                    </button>
                    <button
                      className={`button buttonLogout`}
                      title="Disconnect"
                      onClick={(e) => {
                        setAddressDropdownOpen(false)
                        wallet.disconnect()
                      }}
                    >
                      <img src={LogoutIcon} alt="🚪" />
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default Header
