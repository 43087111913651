import React, { useEffect } from 'react'

import logo from '../images/logo.svg'

declare let window: any;

const offer_price_ETH: Array<number> = []
const offer_price_BNB: Array<number> = []
const offer_price_MATIC: Array<number> = []

const service_fee_ETH: Array<number> = []
const service_fee_BNB: Array<number> = []
const service_fee_MATIC: Array<number> = []

const total_ETH: Array<number> = []
const total_BNB: Array<number> = []
const total_MATIC: Array<number> = []

interface MintCostsResponse {
  offer_price: {
    BNB: number,
    ETH: number,
    MATIC: number,
  },
  service_fee: {
    BNB: number,
    ETH: number,
    MATIC: number,
  },
  total: {
    BNB: number,
    ETH: number,
    MATIC: number,
  }
}
const add = async () => {
  const res = await fetch('https://checkout-api.via.exchange/v0/customer_nft/mint-costs/?nft_collection=bb811382-1f1b-4376-8884-5f74bd808f83&nft_amount=1')
  console.log(res)
  const message: MintCostsResponse = await res.json()

  offer_price_ETH.push(message.offer_price.ETH)
  offer_price_BNB.push(message.offer_price.BNB)
  offer_price_MATIC.push(message.offer_price.MATIC)

  service_fee_ETH.push(message.service_fee.ETH)
  service_fee_BNB.push(message.service_fee.BNB)
  service_fee_MATIC.push(message.service_fee.MATIC)

  total_ETH.push(message.total.ETH)
  total_BNB.push(message.total.BNB)
  total_MATIC.push(message.total.MATIC)

  window.Plotly?.newPlot('offer_price_ETH', [{ y: offer_price_ETH }]);
  window.Plotly?.newPlot('offer_price_BNB', [{ y: offer_price_BNB }]);
  window.Plotly?.newPlot('offer_price_MATIC', [{ y: offer_price_MATIC }]);
  window.Plotly?.newPlot('service_fee_ETH', [{ y: service_fee_ETH }]);
  window.Plotly?.newPlot('service_fee_BNB', [{ y: service_fee_BNB }]);
  window.Plotly?.newPlot('service_fee_MATIC', [{ y: service_fee_MATIC }]);
  window.Plotly?.newPlot('total_ETH', [{ y: total_ETH }]);
  window.Plotly?.newPlot('total_BNB', [{ y: total_BNB }]);
  window.Plotly?.newPlot('total_MATIC', [{ y: total_MATIC }]);
}

const Costs = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.plot.ly/plotly-2.8.3.min.js";
    //script.async = true;
    script.onload = () => {
      console.log('loaded')
      setInterval(add, 1000)
    }

    document.body.appendChild(script);
  }, [])

  return (
    <div className="main" id="main">
      <script src=''></script>

      <div className="Header">
        <a href="https://via.exchange" rel="noreferrer">
          <img src={logo} width="125" alt="siteIcon" />
        </a>
      </div>

      <div>id: bb811382-1f1b-4376-8884-5f74bd808f83</div>
      <div>
        <a target="_blank" href="https://checkout-api.via.exchange/v0/customer_nft/mint-costs/?nft_collection=bb811382-1f1b-4376-8884-5f74bd808f83&nft_amount=1" rel="noreferrer">https://api.via.exchange/v0/customer_nft/mint-costs/?nft_collection=bb811382-1f1b-4376-8884-5f74bd808f83&nft_amount=1</a>
      </div>
      <div>Interval: 1s</div>

      <h2>offer_price_ETH</h2>
      <div id='offer_price_ETH'></div>
      <h2>offer_price_BNB</h2>
      <div id='offer_price_BNB'></div>
      <h2>offer_price_MATIC</h2>
      <div id='offer_price_MATIC'></div>

      <h2>service_fee_ETH</h2>
      <div id='service_fee_ETH'></div>
      <h2>service_fee_BNB</h2>
      <div id='service_fee_BNB'></div>
      <h2>service_fee_MATIC</h2>
      <div id='service_fee_MATIC'></div>

      <h2>total_ETH</h2>
      <div id='total_ETH'></div>
      <h2>total_BNB</h2>
      <div id='total_BNB'></div>
      <h2>total_MATIC</h2>
      <div id='total_MATIC'></div>
    </div>
  )
}

export default Costs
