import React, { useState, useEffect } from 'react'

// icons
import logo from '../images/logo.svg'


import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


let connector
//window.getConnector = () => connector

const WC = (props) => {

  const [isConnected, setConnected] = useState(false)
  const [account, setAccount] = useState(null)
  const [chainId, setChainId] = useState(null)


  const connect = ({ showQR = false }) => {
    /*
      showQR === false | only reconnect
      showQR === true  | try to connect + show QR
    */

    console.log('connect()')

    connector = new WalletConnect({
      bridge: 'https://bridge.walletconnect.org',
      qrcodeModal: QRCodeModal,
    })

    console.log('connector: ', connector)

    if (!connector.connected && showQR/* && connector.session*/) {
      console.log('no session, create')
      connector.createSession()
    } else {
      console.log('restore session: ', connector.session)

      setConnected(connector.session.connected)
      setAccount(connector.session.accounts[0])
      setChainId(connector.session.chainId)
    }

      /*
      Events:
        - connect
        - disconnect
        - session_request
        - session_update
        - call_request
        - wc_sessionRequest
        - wc_sessionUpdate
      */

    connector.on('connect', (error, payload) => { // only after QR scan
      console.log('* connected', payload)
      toast.success('[dapp ⮀ wallet] Connected')

      if (error) {
        throw error
      }

      // Get provided accounts and chainId
      const { accounts, chainId } = payload.params[0]
      console.log(accounts, chainId)

      setConnected(true)
      setAccount(accounts[0])
      setChainId(chainId)
    });


    connector.on('session_request', (error, payload) => { console.log('* session_request', error, payload) })


    connector.on('session_update', (error, payload) => {
      console.log('* session_update', payload)

      if (error) {
        throw error
      }

      console.log('chainId', chainId)

      // Get updated accounts and chainId
      const { /*accounts,*/ chainId: newChainId } = payload.params[0]
      //console.log(accounts, newChainId)

      //const account = accounts[0] // todo: account

      if (newChainId !== chainId) {
        toast.info(`[wallet] chainId changed to ${newChainId}`)
        setChainId(newChainId)
      }
    });


    connector.on('call_request', (error, payload) => { console.log('* call_request', error, payload) })


    connector.on('disconnect', (error, payload) => {
      console.log('* disconnected', payload)

      /*
        "Session Rejected" = reject after QR scan
        "Session Disconnected" = disconnected by dapp
        "Session disconnected" = disconnected by wallet
      */

      if (payload.params[0]?.message === 'Session Rejected') {
        toast.warn('[wallet] Connection rejected')
      }

      if (payload.params[0]?.message === 'Session disconnected') {
        toast.info('[wallet] Disconnected')
      }

      if (payload.params[0]?.message === 'Session Disconnected') {
        toast.info('[dapp] Disconnected')
      }


      if (error) {
        throw error
      }

      setConnected(false)
      setAccount(null)
      setChainId(null)
    })
  }

  useEffect(() => {
    connect({ showQR: false })
  }, [])


  const sendTx = () => {
    console.log('sendTx...')

    const tx = {
      from: account, // Required
      to: '0x0000000000000000000000000000000000000000', // Required (for non contract deployments)
      // data: '0x', // Required
      //gasPrice: '0x02540be400', // Optional
      //gas: '0x9c40', // Optional
      value: '0x00', // Optional
      //nonce: '0x00', // Optional
    }

    // Send transaction
    connector
      .sendTransaction(tx)
      .then((result) => {
        // Returns transaction id (hash)
        console.log('sendTransaction ok', result)
        toast.success('[wallet] tx submitted')
      })
      .catch((error) => {
        // Error returned when rejected
        console.error('sendTransaction error', error)
        toast.warn('[wallet] tx rejected')
      })
  }

  const changeNetwork = () => { // not implemented
    console.log('changeNetwork...')

    const customRequest = {
      id: 1,
      jsonrpc: "2.0",
      method: "wallet_switchEthereumChain",
      params: [{ chainId: '0x01' }],
    }

    // Send Custom Request
    connector
      .sendCustomRequest(customRequest)
      .then(result => {
        console.log('sendCustomRequest success', result);
      })
      .catch(error => {
        console.error('sendCustomRequest error', error);
      });
  }

  const requestAccounts = () => { // not implemented
    console.log('requestAccounts...')

    const customRequest = {
      id: 1,
      jsonrpc: "2.0",
      method: "eth_requestAccounts",
    }

    connector
      .sendCustomRequest(customRequest)
      .then(result => {
        console.log('request success', result);
      })
      .catch(error => {
        console.error('request error', error);
      });
  }

  const requestEstimateGas = () => { // not implemented
    console.log('requestEstimateGas...')

    const customRequest = {
      id: 1,
      jsonrpc: "2.0",
      method: "eth_estimateGas",
      params: [{
        from: "0xbc28Ea04101F03aA7a94C1379bc3AB32E65e62d3",
        to: "0x89D24A7b4cCB1b6fAA2625Fe562bDd9A23260359",
        data: "0x",
        gasPrice: "0x02540be400",
        gas: "0x9c40",
        value: "0x00",
        nonce: "0x0114",
      }]
    }

    connector
      .sendCustomRequest(customRequest)
      .then(result => {
        console.log('request success', result);
      })
      .catch(error => {
        console.error('request error', error);
      });
  }

  return (
    <div className="main" id="main">
      <div className="Header">
        <a href="https://via.exchange" rel="noreferrer">
          <img src={logo} width="125" alt="siteIcon" />
        </a>
      </div>
      <h1>WalletConnect test</h1>
      <br />
      <button disabled={isConnected} onClick={() => { connect({ showQR: true }) }}>Connect</button>
      <br />
      <p>connected: {isConnected ? 'yes' : 'no'}</p>
      <br />
      <p>chainId: {chainId || 'null'}</p>
      <br />
      <p>account: {account || 'null'}</p>
      <br />
      <br />
      <button disabled={!isConnected} onClick={sendTx}>Send tx (request wallet)</button>
      <br />
      <button disabled={!isConnected} onClick={() => { connector.killSession() }}>Disconnect</button>
      <br />
      <div>-----------------------------------------------</div>
      <h3>Requests (don't work in metamask)</h3>
      <button disabled={!isConnected} onClick={changeNetwork}>Change network 0x01</button>
      <br />
      <button disabled={!isConnected} onClick={requestAccounts}>Request accounts</button>
      <br />
      <button disabled={!isConnected} onClick={requestEstimateGas}>Estimate gas</button>
      <br />
      <ToastContainer />
    </div>
  )
}

export default WC
