import React from 'react'


const Skeleton = ({ w = 80, h = 40, r = 30, style } : {
  w?: number | string,
  h?: number | string,
  r?: number | string,
  style?: any
}) => {
  return (
    <div
      className="Skeleton"
      style={{
        width: w,
        minWidth: w,
        height: h,
        borderRadius: r,
        backgroundColor: '#88888822',
        ...style
      }}
    />
  )
}

export default Skeleton
