/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'

import { Context } from '../Context'
import { WalletContext } from './../web3-wallets'

// icons
import CloseIcon from '../images/close.svg'
import MetamaskIcon from '../images/wallet-metamask.svg'
import WalletConnectIcon from '../images/wallet-walletconnect.svg'
//import TorusIcon from '../images/wallet-torus.svg'
import RainbowIcon from '../images/wallet-rainbow.png'
import PhantomIcon from '../images/wallet-phantom.svg'
//import CoinbaseIcon from '../images/wallet-coinbase.svg'


const SelectWallet = ({ close }) => {
  const wallet = useContext(WalletContext)
  const {
    networks,
    currentNetworkIndex,
    //isWalletLoading
  } = useContext(Context)

  return (
    <div className="cardBg">
      <div className="card cardSelect cardSelectWallet">
        <div className="cardContent">
          <a className="close" onClick={close}>
            <img src={CloseIcon} alt="❌" />
          </a>
          <p className="selectWalletTitle">Connect your wallet</p>
 
          <div className="wallets">
            <a
              className="walletItem"
              onClick={async () => {
                const network = networks[Number(currentNetworkIndex)]
                const isConnected = await wallet.connect({
                  name: 'MetaMask',
                  chainId: network.chain_id
                })
                if (isConnected) {
                  close()
                }
              }
            }>
              <div className="walletIconWrapper">
                <img src={/*isWalletLoading ? "https://app.1inch.io/assets/images/loading.png" : */MetamaskIcon} width="32" alt="Metamask" />
              </div>
              <p className="walletName">Metamask</p>
            </a>

            <div
              className="walletItem"
              onClick={async () => {
                const network = networks[Number(currentNetworkIndex)]
                const isConnected = await wallet.connect({
                  name: 'WalletConnect',
                  chainId: network.chain_id
                })
                if (isConnected) {
                  close()
                }
              }}
            >
              <div className="walletIconWrapper">
                <img src={WalletConnectIcon} width="32" alt="WalletConnect" />
              </div>
              <p className="walletName">Wallet Connect</p>
            </div>

            <div
              className="walletItem"
              onClick={async () => {
                const network = networks[Number(currentNetworkIndex)]
                const isConnected = await wallet.connect({
                  name: 'WalletConnect',
                  chainId: network.chainId
                })
                if (isConnected) {
                  close()
                }
              }}
            >
              <div className="walletIconWrapper">
                <img src={RainbowIcon} width="32" alt="Rainbow" />
              </div>
              <p className="walletName">Rainbow</p>
            </div>
            {/*<div className={`${styles.walletItem} ${styles.disabledWallet}`}>
              <div className="walletIconWrapper">
                <img src={TorusIcon} alt="Torus" />
              </div>
              <p className="walletName">Torus</p>
              <div className="soon">Soon</div>
            </div>*/}
            {/*<div className={`${styles.walletItem} ${styles.disabledWallet}`}>
              <div className="walletIconWrapper">
                <img src={RainbowIcon} alt="Rainbow" />
              </div>
              <p className="walletName">Rainbow</p>
              <div className="soon">Soon</div>
            </div>*/}
            <div
              className="walletItem disabledWallet"
              onClick={async () => {
                return false

                //const network = networks[Number(currentNetworkIndex)]
                const isConnected = await wallet.connect({
                  name: 'Phantom',
                  chainId: -1
                })
                if (isConnected) {
                  close()
                }
              }
            }>
              <div className="walletIconWrapper">
                <img src={PhantomIcon} width="32" alt="Phantom" />
              </div>
              <p className="walletName">Phantom</p>
            </div>
            {/*<div className={`walletItem disabledWallet`}>
              <div className="walletIconWrapper">
                <img src={CoinbaseIcon} alt="Coinbase" />
              </div>
              <p className="walletName">Coinbase</p>
              <div className="soon">Soon</div>
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectWallet
