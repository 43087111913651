import React from 'react'

import { IconVia, IconQuestion } from './Icons'

const Footer = () => {
  return (
    <footer>
      <a href="https://via.exchange" target="_blank" rel="noreferrer">
        <span>Powered by</span>
        <IconVia />
      </a>

      <a href="https://docs.via.exchange/product-docs/cross-chain-checkout/how-cross-chain-checkout-works" target="_blank" rel="noreferrer">
        <IconQuestion />
        <span>How does it work?</span>
      </a>
    </footer>
  )
}

export default Footer
