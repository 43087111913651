import React, { useContext } from 'react'

// utils
import { Context } from '../Context'

import Indicator from './Indicator'
import TimerButton from './TimerButton'

// icons
import CloseIcon from '../images/close.svg'
import { IconExplorer } from '../components/Icons'


const ConfirmTx = ({ isSuccess, close }) => {
  const {
    currentNetworkIndex,
    networks,
    payTxHash
  } = useContext(Context)

  return (
    <div className="cardBg">
      <div className="card cardConfirmTx">
        <a className="close" onClick={close}>
          <img src={CloseIcon} alt="❌" />
        </a>
        <div className="cardMain">
          <div className="indicatorPlace">
            <Indicator status={isSuccess ? 'success' : 'wait'} />
          </div>
          {!isSuccess ?
            <div className="cardTitle">
              Confirm transaction
              <br />
              in your wallet
            </div>
            :
            <>
              <div className="cardTitle">Transaction submitted</div>
              <div>
                <a className="payTxLink" href={`${networks[Number(currentNetworkIndex)]?.explorer_url}/tx/${payTxHash}` || '#'} target="_blank">
                  <IconExplorer />
                  <span>Open Explorer</span>
                </a>
              </div>
              <TimerButton className="closeButton" timeout={5} onClick={close}>
                <span>Close</span>
              </TimerButton>
              {/*<button className="closeButton" type="button" onClick={close}>Close</button>*/}
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default ConfirmTx
